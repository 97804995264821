import * as $api from './request'
import store from '@/store/index'
/*********************************** 注册登录 start ***********************************/
// 获取验证码
export const getVerifyCode = () => {
  return $api.getFunc({ url: `/sys/randomImage/abc123456` })
}
// 注册
export const register = (data) => {
  return $api.postFunc({ url: `/sys/reportUserRegister`, data: data })
}
export const login = (data) => {
  return $api.postFunc({ url: `/sys/reportUserLogin`, data: data })
}
/*********************************** 注册登录 end ***********************************/

/*********************************** 字典接口API start ***********************************/
/**
 * @param {string} dictCode:字典编码
 */
export const getDictByDicCode = (dictCode) => {
  return $api.getFunc({ url: `/sys/api/queryEnableDictItemsByCode?code=${dictCode}` })
}
/*********************************** 字典接口API start ***********************************/

/*********************************** 成果展示系统API start ***********************************/
//  成果发布
export const addSummary = (data) => {
  return $api.postFunc({ url: '/plat/achievement/add', data: data })
}
//  编辑成果
export const editSummary = (data) => {
  return $api.putFunc({ url: '/plat/achievement/edit', data: data })
}
// 成果分页列表
export const getSummaryList = (data) => {
  return $api.getFunc({ url: '/plat/achievement/list', data: data })
}
// 成果详情
export const getSummaryDetail = (id) => {
  return $api.getFunc({ url: `/plat/achievement/queryById?id=${id}` })
}

// 成果排名列表
export const getSummaryRank = () => {
  return $api.getFunc({ url: `/plat/achievement/hotlist?pageSize=3` })
}
/*********************************** 成果展示系统API end ***********************************/

/*********************************** 合作需求系统API start ***********************************/
//  需求发布
export const addRequirement = (data) => {
  return $api.postFunc({ url: '/plat/needPub/add', data: data })
}
//  编辑需求
export const editRequirement = (data) => {
  return $api.postFunc({ url: '/plat/needPub/edit', data: data })
}
// 需求分页列表
export const getRequirementList = (data) => {
  return $api.getFunc({ url: '/plat/needPub/list', data: data })
}
// 需求详情
export const getRequirementDetail = (id) => {
  return $api.getFunc({ url: `/plat/needPub/queryById?id=${id}` })
}

// 需求排名列表
export const getRequirementRank = () => {
  return $api.getFunc({ url: `/plat/needPub/hotList?pageSize=3` })
}
/*********************************** 合作需求系统API end ***********************************/

/*********************************** 专家库系统API start ***********************************/
// 专家库分页列表
export const getProfessionalList = (data) => {
  return $api.getFunc({ url: '/plat/experts/list', data: data })
}
// 专家详情
export const getProfessionalDetail = (id) => {
  return $api.getFunc({ url: `/plat/experts/queryById?id=${id}` })
}
/*********************************** 专家库系统API end ***********************************/

/*********************************** 政策法规系统API start ***********************************/
// 政策法规分页列表
export const getPolicyList = (data) => {
  return $api.getFunc({ url: '/plat/policy/list', data: data })
}
// 政策法规详情
export const getPolicyDetail = (id) => {
  return $api.getFunc({ url: `/plat/policy/queryById?id=${id}` })
}
/*********************************** 政策法规系统API end ***********************************/

/*********************************** 活动新闻系统API start ***********************************/
// 新闻分页列表
export const getNewsList = (data) => {
  return $api.getFunc({ url: '/plat/news/list', data: data })
}
// 新闻详情
export const getNewsDetail = (id) => {
  return $api.getFunc({ url: `/plat/news/queryById?id=${id}` })
}

// 活动分页列表
export const getActiveList = (data) => {
  return $api.getFunc({ url: '/plat/activities/list', data: data })
}

// 活动详情
export const getActiveDetail = (id) => {
  return $api.getFunc({ url: `/plat/activities/queryById?id=${id}` })
}
/*********************************** 活动新闻系统API end ***********************************/

/*********************************** 供应商API start ***********************************/
// 供应商分页列表
export const getConstructorList = (data) => {
  return $api.getFunc({ url: '/plat/serviceSeller/list', data: data })
}
/*********************************** 供应商API end ***********************************/

/*********************************** 供应商API start ***********************************/
// 团队分页列表
export const getTeams = () => {
  return $api.getFunc({ url: '/plat/userGroupItem/list?pageSize=999' })
}
/*********************************** 供应商API end ***********************************/

// 新增团队
export const addTeam = (data) => {
  return $api.postFunc({ url: '/plat/userGroup/add', data: data })
}

// 团队详情
export const getTeamDetail = (id) => {
  return $api.getFunc({ url: `/plat/userGroup/queryById?id=${id}` })
}

//  团队列表
export const getTeamList = () => {
  return $api.getFunc({ url: `/plat/userGroup/list?pageSize=999&pageNo=1&userId=${store.state.userInfo.userInfo.id}` })
}

//  获取团队成员
export const getTeamMember = (id) => {
  return $api.getFunc({ url: `/plat/userGroupItem/list?userGroupId=${id}&pageSize=999` })
}

//  新增团队成员
export const addTeamMember = (data) => {
  return $api.postFunc({ url: '/plat/userGroupItem/add', data: data })
}

//  修改团队成员
export const editTeamMember = (data) => {
  return $api.putFunc({ url: '/plat/userGroupItem/edit', data: data })
}

//  事迹列表
export const getTeamEvent = (id) => {
  return $api.getFunc({ url: `/plat/userGroupEvent/list?userGroupId=${id}&pageSize=999` })
}

//  新增事迹
export const addTeamEvent = (data) => {
  return $api.postFunc({ url: '/plat/userGroupEvent/add', data: data })
}
//  编辑事迹
export const editTeamEvents = (data) => {
  return $api.putFunc({ url: '/plat/userGroupEvent/edit', data: data })
}

// 邀请专家
export const inviteExperts = (data) => {
  return $api.postFunc({ url: `/plat/achievementExpertComment/add?expertIds=${data.expertIds}&achievementId=${data.achievementId}` })
}


//  专家评价列表
export const getExpertsComment = (id) => {
  return $api.getFunc({ url: `/plat/achievementExpertComment/list?achievementId=${id}&pageSize=999` })
}


// 投融资服务
export const getInvestment = () => {
  return $api.getFunc({ url: `/plat/investment/list??pageSize=4` })
}

// 活动报名
export const baomingActivity = (data) => {
  return $api.postFunc({ url: `/plat/activitiesBaoming/add`, data: data })
}

// 联系技术方
export const contactMe = (data) => {
  return $api.postFunc({ url: `/plat/contactMe/add`, data: data })
}

// 联系我的列表
export const contactMeList = (data) => {
  return $api.getFunc({ url: `/plat/contactMe/contactMeList`, data: data })
}

// 删除团队
export const deleteTeam = (id) => {
  return $api.delFunc({ url: `/plat/userGroup/delete?id=${id}` })
}
// 删除成员
export const deleteMember = (id) => {
  return $api.delFunc({ url: `/plat/userGroupItem/delete?id=${id}` })
}
// 删除大事迹
export const deleteEvent = (id) => {
  return $api.delFunc({ url: `/plat/userGroupEvent/delete?id=${id}` })
}

// 删除我发布的需求
export const deleteRequirement = (id) => {
  return $api.delFunc({ url: `/plat/needPub/delete?id=${id}` })
}

// 删除我发布的成果
export const deleteSummary = (id) => {
  return $api.delFunc({ url: `/plat/achievement/delete?id=${id}` })
}

// 获取竞争信息
export const getCompetitiveInfo = (id) => {
  return $api.getFunc({ url: `/plat/achievement/analyse?achievementId=${id}` })
}

// 编辑用户团队
export const editTeam = (data) => {
  return $api.putFunc({ url: `/plat/userGroup/edit`, data: data })
}

// 意见反馈 - 留言
export const leaveFeedBack = (data) => {
  return $api.postFunc({ url: `/plat/leaveWord/add`, data: data })
}

// 帮助中心
export const getHelpCenter = (data) => {
  return $api.getFunc({ url: `/plat/help/list`, data: data })
}

// 网站底部配置的信息
export const getWebsiteFooterConfig = () => {
  return  $api.getFunc({ url: `/plat/commParam/queryByCode?code=page_footer` })
}
