<template>
  <el-dialog :title="dialogTitle"
             :close-on-click-modal="false"
             width="40%"
             :visible.sync="dialogFormVisible">
    <el-form :model="form"
             class="add-member-form"
             ref="addMemberForm"
             label-width="120px"
             label-position="left">
      <el-form-item label="您的联系方式"
                    required>
        <el-input v-model="form.userPhone"></el-input>
      </el-form-item>
      <el-form-item label="给对方留言">
        <el-input v-model="form.userContent"
                  type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary"
                 @click="confirm"
      >确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { contactMe } from '@/api/apiFunc'

export default {
  name: 'CommentDialog',
  data () {
    return {
      dialogTitle: '',
      dialogFormVisible: false,
      module: '',
      id: '',
      form: {
        userPhone: '',
        userContent: ''
      },
    }
  },
  methods: {
    init (options) {
      this.dialogTitle = options.title
      this.dialogFormVisible = true
      this.module = options.module
      this.id = options.id
    },
    confirm () {
      let data = {}
      if (this.module === 'summary') {
        data = {
          'achievementId': this.id,
          'userId': this.$store.state.userInfo.userInfo.id,
        }
      } else {
        data = {
          'needPubId': this.id,
          'userId': this.$store.state.userInfo.userInfo.id,
        }
      }
      contactMe(Object.assign({}, data, this.form)).then(res => {
        console.log(res)
        if (res.data.code === 200){
          this.$notify({
            title: '成功',
            message: '联系成功。',
            type: 'success'
          })
          this.dialogFormVisible = false;
        }
      }).catch(err => {
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>

</style>
