import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    userInfo: {}
  },
  mutations: {
    SET_USER_INFO (store, userInfo) {
      localStorage.setItem('token', userInfo.token)
      store.userInfo = userInfo
      store.isLogin = true
    },
    CLEAR_USER_INFO (store) {
      localStorage.setItem('token', '')
      store.userInfo = {}
      store.isLogin = false
    },
  },
  plugins:[
    createPersistedState({
      storage:window.localStorage // 注意
    })
  ]

})

