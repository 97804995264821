<template>
  <div class="w1240 flex-column">
    <div class="header df">
      <img :src="baseAssetsUrl + summaryInfo.produceImgs.split(',')[0]"
           style="width: 412px;height: 280px;"
           alt="">
      <div class="profile-container flex-column space-between">
        <h3 class="title">{{summaryInfo.title}}</h3>
        <p class="progress">成果画像信息完整度:{{summaryInfo.finishRatio || 0 + '%'}}</p>
        <p class="profile">{{summaryInfo.remark}}</p>
        <div class="profile-contact df align-center">
          <el-button style="width: 255px;height: 58px;font-size: 20px"
                     @click="contactTech"
                     v-is-login
                     type="primary">联系技术方
          </el-button>
          <div class="price">
            <span>合作价格</span>
            <span class="meet">面议/{{ summaryInfo.cooperateAmount }}</span>
            <span class="read-num"><i class="el-icon-view"></i> {{summaryInfo.seeNum || 0}}人浏览</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom df">
      <div class="item flex-column align-center" style="width: 116px;">
        <span class="content">{{dictionary.belongTypes[summaryInfo.type]}}</span>
        <span class="title">所属类别</span>
      </div>
      <div class="item flex-column align-center" style="width: 190px;">
        <span class="content">{{summaryInfo.patentNo}}</span>
        <span class="title">专利号</span>
      </div>
      <div class="item flex-column align-center" style="width: 116px;">
<!--        TODO:字典-->
        <span class="content">{{dictionary.patentTypes[summaryInfo.patentType]}}</span>
        <span class="title">专利类型</span>
      </div>
      <div class="item flex-column align-center" style="width: 116px;">
        <span class="content">{{dictionary.tradeTypes[summaryInfo.tradeType]}}</span>
        <span class="title">行业分类</span>
      </div>
<!--      <div class="item flex-column align-center">-->
<!--        <span class="content">{{summaryInfo.appRange}}</span>-->
<!--        <span class="title">所有人</span>-->
<!--      </div>-->
      <div class="item flex-column align-center" style="width: 130px;">
        <span class="content">{{summaryInfo.city}}</span>
        <span class="title">所属地区</span>
      </div>
      <div class="item flex-column align-center" style="width: 212px;">
        <span class="content">{{summaryInfo.createTime}}</span>
        <span class="title">发布时间</span>
      </div>
    </div>
    <comment-dialog ref="commentsDialog"></comment-dialog>
  </div>
</template>

<script>
import { baseAssetsUrl } from '@/api/config'
import { dictionary } from '@/dictionary/dictionary'
import CommentDialog from '@/components/CommentDialog'
export default {
  name: "SummaryDetailProfile",
  components: { CommentDialog },
  props:{
    summaryInfo: {
      type: Object,
      default: ()=>{
        return {
          produceImgs: []
        }
      }
    }
  },
  data() {
    return {
      baseAssetsUrl: baseAssetsUrl,
      dictionary: dictionary
    }
  },
  methods: {
    contactTech() {
      this.$refs.commentsDialog.init({title: '联系技术方',module: 'summary',id: this.summaryInfo.id});
    },
  },
};
</script>

<style lang="scss"
       scoped>
@import "@/styles/_variables.scss";

.profile-container {
  margin-left: 20px;
  max-width: 736px;

  .title {
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .progress {
    margin-top: 0;
    color: $main-color;
    font-size: 18px;
  }

  .profile {
    margin: 0 0 20px 0;
    font-size: 20px;
    line-height: 2;
  }

  .price {
    margin-left: 30px;
  }

  .meet {
    font-size: 40px;
    color: #F50F0F;
    margin-left: 24px;
    margin-right: 22px;
  }
}
.bottom{
  padding-top: 40px;
  padding-bottom: 40px;
  .item{
    padding: 0 30px;
    font-size: 18px;
    &:nth-of-type(-n+5){
      border-right: 1px solid #eee;
    }
    .title{
      padding-top: 16px;
      color: #737373;
    }
  }
}
.read-num{
  color: $text-color-grey;
}
</style>
