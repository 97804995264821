<template>
  <div class="summary-idea">
    <content-header title="团队简介">
      <template slot="content">
        {{groupInfo.remark}}
      </template>
    </content-header>
    <content-header title="成员概况">
      <template slot="content">
        <el-descriptions :column="4"
                         size="small"
                         v-for="(user) in groupInfo.userGroupItems"
                         :key="user.id"
                         style="margin-bottom: 4px;"
                         border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              姓名
            </template>
            {{user.name}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              性别
            </template>
            {{user.gender === 1 ? '男' : '女'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              最高学历
            </template>
            {{ dictionary.qualifications[user.qualification] }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              毕业院校
            </template>
            {{user.school}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              职称
            </template>
            {{user.positionalTitles}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              研究方向
            </template>
            {{user.researchDirection}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              研究领域
            </template>
            {{user.domain}}
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </content-header>
    <content-header title="团队亮点">
      <template slot="content">{{groupInfo.feature}}</template>
    </content-header>
    <content-header title="细分研究方向">
      <template slot="content">{{groupInfo.researchInterests}}</template>
    </content-header>
    <content-header title="团队现有成果汇总">
      <template slot="content">{{groupInfo.achievementGather}}</template>
    </content-header>
    <content-header title="团队动态">
      <template slot="content">
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in groupInfo.userGroupEvents"
            :key="activity.id"
            color="#E7742B"
            :timestamp="activity.eventContent">
            {{ activity.eventDate }}
          </el-timeline-item>
        </el-timeline>
      </template>
    </content-header>
  </div>
</template>

<script>
import ContentHeader from "@/components/ContentHeader";
import { getTeamDetail } from '@/api/apiFunc'
import { dictionary } from '@/dictionary/dictionary'

export default {
  name: "DevelopTeam",
  components: { ContentHeader },
  props: ['summaryInfo'],
  data () {
    return {
      groupInfo: {},
      activities: [
        {
          content: "荣获百佳企业称号",
          timestamp: "2018-04-20",
          color: "#E7742B"
        },
        {
          content: "甲醇回收计划",
          timestamp: "2018-04-15",
          color: "#E7742B"
        },
        {
          content: "乙醇回收技术",
          timestamp: "2018-04-12",
          color: "#E7742B"
        },
        {
          content: "荣获电子商务企业称号",
          timestamp: "2018-04-03",
          color: "#E7742B"
        }],
      dictionary: dictionary,
    };
  },
  mounted () {
    getTeamDetail(this.summaryInfo.groupId).then(res=>{
      this.groupInfo = res.data.result;
    }).catch(err=>{
      console.log(err)
    })
  },
};
</script>

<style lang="scss"
       scoped>
.mt10 {
  margin-top: 10px;
}
</style>
