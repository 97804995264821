const dictionary = {
  policy_type: [
    {
      label: '全部政策',
      value: ''
    },
    {
      label: '国家政策',
      value: '1'
    },
    {
      label: '地方政策',
      value: '2'
    },
    {
      label: '行业政策',
      value: '3'
    },
  ],
  citys: [
    { label: '不限', value: '' },
    { label: '成都市', value: '四川-成都市' },
    { label: '绵阳市', value: '四川-绵阳市' },
    { label: '德阳市', value: '四川-德阳市' },
    { label: '泸州市', value: '四川-泸州市' },
    { label: '广元市', value: '四川-广元市' },
    { label: '遂宁市', value: '四川-遂宁市' },
    { label: '南充市', value: '四川-南充市' },
    { label: '宜宾市', value: '四川-宜宾市' },
    { label: '达州市', value: '四川-达州市' },
    { label: '攀枝花市', value: '四川-攀枝花市' },
    { label: '眉山市', value: '四川-眉山市' },
    { label: '广安市', value: '四川-广安市' },
    { label: '雅安市', value: '四川-雅安市' },
    { label: '巴中市', value: '四川-巴中市' },
    { label: '内江市', value: '四川-内江市' },
    { label: '乐山市', value: '四川-乐山市' },
    { label: '资阳市', value: '四川-资阳市' },
    { label: '自贡市', value: '四川-自贡市' },
  ],
  tradeTypes: {
    '1': '电子信息',
    '2': '生物医药',
    '3': '航空航天',
    '4': '新材料',
    '5': '高技术服务',
    '6': '新能源与节能',
    '7': '资源与环境',
    '8': '高新技术改造传统产业',
  },
  belongTypes: {
    '1': '技术需求',
    '2': '成果需求',
    '3': '合作需求',
  },
  patentTypes: {
    '1': '发明专利',
    '2': '实用新型专利',
    '3': '外观设计专利',
    '4': '其他（软件著作权、电子电路设计、新医药配方等）',
  },
  achievementStatus: {
    '1': '未转移转化',
    '2': '已转化'
  },
  qualifications: {
    '1': '博士',
    '2': '硕士',
    '3': '研究生',
    '4': '本科',
    '5': '专科',
    '6': '中专/高中',
    '7': '初中',
    '8': '小学',
  },
  need_pub_needs_status: {
    "1": '未解决',
    "2": '已解决'
  }
}

export {
  dictionary
}
