<template>
<div class="help-center">
  <div class="header-container">
    <div class="full-container">
      <div class="bread-list">
        <div class="top-search">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{name:'TheIndex'}">首页</el-breadcrumb-item>
            <el-breadcrumb-item>帮助中心</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div class="help-center-content w1240">
      <el-tabs tab-position="left" style="min-height: 500px;" v-model="tabName" @tab-click="clickTab">
        <el-tab-pane v-for="(item,index) in tabs"
                     :key="index"
                     :label="item.label"
                     :name="item.value">
          <div v-if="tabName === '1'">
            <div class="w1240 flex-column align-center">
              <h3>{{ detail.title }}</h3>
              <div class="article-mark flex-sb">
                <span>{{ detail.createTime }}</span>
              </div>
              <div class="article-content" v-html="detail.content"></div>
            </div>
          </div>
          <div v-if="tabName === '2'">
            <div class="w1240 flex-column align-center">
              <h3>{{ detail.title }}</h3>
              <div class="article-mark flex-sb">
                <span>{{ detail.createTime }}</span>
              </div>
              <div class="article-content" v-html="detail.content"></div>
            </div>
          </div>
          <div v-if="tabName === '3'">
            <div class="w1240 flex-column align-center">
              <h3>{{ detail.title }}</h3>
              <div class="article-mark flex-sb">
                <span>{{ detail.createTime }}</span>
              </div>
              <div class="article-content" v-html="detail.content"></div>
            </div>
          </div>
          <div v-if="tabName === '9'">
            <div class="w1240 flex-column align-center">
              <h3>{{ detail.title }}</h3>
              <div class="article-mark flex-sb">
                <span>{{ detail.createTime }}</span>
              </div>
              <div class="article-content" v-html="detail.content"></div>
            </div>
          </div>
          <div v-if="tabName === '10'">
            <div class="w1240 flex-column align-center">
              <h3>{{ detail.title }}</h3>
              <div class="article-mark flex-sb">
                <span>{{ detail.createTime }}</span>
              </div>
              <div class="article-content" v-html="detail.content"></div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</div>
</template>

<script>
import { getDictByDicCode, getHelpCenter } from '@/api/apiFunc'

export default {
  name: "HelpCenter",
  data() {
    return {
      tabName: '1',
      detail: {},
      tabs: []
    }
  },
  watch: {
    tabName: function () {
      this.getPageData()
    }
  },
  mounted () {
    getDictByDicCode('help_type').then(res=>{
      this.tabs = res.data
    })
    this.getPageData()
  },
  methods: {
    clickTab(tab){
      this.tabName = tab.name;
    },
    getPageData() {
      console.log(this.tabName)
      getHelpCenter({
        pageSize: 1,
        type: this.tabName
      }).then(res=>{
        this.detail = res?.data?.result?.records[0] || {}
      }).catch(err=>{
        console.log(err)
      })
    }
  }
};
</script>

<style lang="scss">
.help-center {
  background: #fff;
}
.article-mark {
  //width: 240px;
  color: #8b8b8b;
  font-size: 16px;
  margin-bottom: 20px;
}
.article-content {
  width: 100%;
  font-size: 16px;
  text-indent: 32px;
  line-height: 2;
  padding-bottom: 80px;
}
</style>
