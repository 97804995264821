import Vue from 'vue'
import store from '@/store/index'
// 注册一个全局自定义指令 `v-focus`
Vue.directive('is-login', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function (el) {
    if (!store.state.isLogin) {
      el.remove()
    }
  },
})
