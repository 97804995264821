<template>
<div class="summary-idea">
  <div id="main_charts"></div>
  <content-header title="行业竞争分析">
    <template slot="content">
      {{summaryInfo.professionAnalyse}}
    </template>
  </content-header>
  <content-header title="市场需求状态">
    <template slot="content">
      {{summaryInfo.marketNeeds}}
    </template>
  </content-header>
</div>
</template>

<script>
import ContentHeader from "@/components/ContentHeader";
import * as echarts from "echarts";
import { getCompetitiveInfo } from '@/api/apiFunc'
export default {
  name: "CompetitionAnalysis",
  components: { ContentHeader },
  props: ['summaryInfo'],
  data() {
    return {
      option: {
        title: {
          text: '综合92',
          x: 'center',
          y: 'center',
          textStyle: {
            color: '#ffffff',
            fontSize: 24,
          },
        },
        radar: {
          indicator: [
            { name: '团队能力', max: 100 },
            { name: '技术能力', max: 100 },
            { name: '财务数据', max: 100 },
            { name: '风险', max: 100 },
            { name: '竞争力', max: 100 },
          ],
          axisName: {
            fontSize: 14,
            color: '#000'
          },
        },
        series: [
          {
            name: 'Budget vs spending',
            type: 'radar',
            itemStyle: {
              color: '#F1B792'
            },
            data: [
              {
                value: [90, 90, 88, 70, 95],
                label: {
                  show: true,
                  fontSize: 14,
                  color: '#000',
                  formatter: function (params) {
                    return params.value;
                  }
                },
                lineStyle: {
                  color: '#E68140'
                },
                areaStyle: {
                  color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                    {
                      color: '#FAE8DD',
                      offset: 0
                    },
                    {
                      color: '#FAE8DD',
                      offset: 1
                    }
                  ])
                }
              },
              // {
              //   value: [70, 65, 60, 40, 60],
              //   label: {
              //     show: true,
              //     formatter: function (params) {
              //       return params.value;
              //     }
              //   },
              //   symbol: 'none',
              //   lineStyle: {
              //     color: '#E68140'
              //   },
              //   areaStyle: {
              //     color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
              //       {
              //         color: '#F1B792',
              //         offset: 0
              //       },
              //       {
              //         color: '#F1B792',
              //         offset: 1
              //       }
              //     ])
              //   }
              // }
            ]
          }
        ]
      }
    }
  },
  mounted () {

    this.getInfo()
  },
  methods: {
    getInfo() {
      getCompetitiveInfo(this.summaryInfo.id).then(res=>{
        this.option.title.text = '(综合)' +  res.data.result.zonghe
        this.option.series[0].data[0]['value'] = [
          res.data.result.tuanDui,
          res.data.result.jiShu,
          res.data.result.caiwu,
          res.data.result.fengxian,
          res.data.result.jingzhengli,
        ]
        let chartDom = document.getElementById('main_charts');
        let myChart = echarts.init(chartDom);
        this.option && myChart.setOption(this.option);
      }).catch(err=>{
        console.log(err)
      })
    }
  }
};
</script>

<style lang="scss" scoped>
#main_charts{
  min-width: 500px;
  width: 550px;
  height: 460px;
}
</style>
