<template>
  <div class="news-detail">
    <div class="header-container">
      <div class="bread-list">
        <div class="top-search">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{name:'TheIndex'}">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{name:'ActiveNews'}">活动新闻</el-breadcrumb-item>
            <el-breadcrumb-item>新闻信息</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <el-divider class="top-divider"></el-divider>
      <div class="policy-content w1240 flex-column align-center">
        <h3>{{ detail.title }}</h3>
        <div class="article-mark flex-sb">
          <span>{{ detail.createTime }}</span>
          <span>{{detail.seeNum || 0}}人浏览</span>
        </div>
        <div class="article-content" v-html="detail.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsDetail } from '@/api/apiFunc'

export default {
  name: "NewsDetail",
  data() {
    return {
      detail: {}
    }
  },
  mounted () {
    getNewsDetail(this.$route.query.id).then(res=>{
      this.detail = res.data.result;
    })
  },
};
</script>

<style lang="scss">
.news-detail {
  background: #fff;

  .policy-content {
    h3 {
      font-size: 30px;
    }

    .article-mark {
      width: 240px;
      color: #8b8b8b;
      font-size: 16px;
      margin-bottom: 20px;
    }

    .article-content {
      font-size: 16px;
      text-indent: 32px;
      line-height: 2;
      padding-bottom: 80px;
    }
  }
}
</style>
