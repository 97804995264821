<template>
<div class="policy-rules">
  <div class="header-container">
      <div class="bread-list">
        <div class="top-search">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{name:'TheIndex'}">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{name:'PolicyRules'}">政策法规</el-breadcrumb-item>
            <el-breadcrumb-item>政策法规详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <el-divider class="top-divider"></el-divider>
    <div class="policy-rules-content w1240 flex-column align-center">
      <h3>{{article.title}}</h3>
      <div class="number">{{article.keyword}}</div>
      <div class="policy-sub-mark flex-sb">
        <span>{{article.createTime.split(" ")[0]}}</span>
        <span>{{article.seeNum || 0}}人浏览</span>
      </div>
      <div class="policy-articles">
        {{article.content}}
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { getPolicyDetail } from '@/api/apiFunc'

export default {
  name: "PolicyRulesDetail",
  data() {
    return {
      article: {
        title: '',
        keyword: '',
        createTime: '',
        seeNum: 0,
        content: ''
      }
    }
  },
  mounted () {
    this.getDetail();
  },
  methods: {
    getDetail() {
      getPolicyDetail(this.$route.query.id).then(res=>{
        this.article = res.data.result
      }).catch(err=>{})
    }
  }
};
</script>

<style lang="scss">
.policy-rules {
  background: #fff;
.policy-rules-content{
  display: flex;
  padding-bottom: 40px;
}
  h3{
    font-size: 24px;
  }
  .number{
    font-size: 28px;
  }
  .policy-sub-mark{
    width: 200px;
    color: #8b8b8b;
    margin: 18px 0;
    font-size: 16px;
  }
  .policy-articles{
    line-height: 2;
  }
}
</style>
