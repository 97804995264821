<template>
<div class="summary-idea">
  <content-header title="主要技术指标">
    <template slot="content">
      {{summaryInfo.mainQuota}}
    </template>
  </content-header>
  <content-header title="创新程度">
    <template slot="content">{{summaryInfo.innovativeness	}}</template>
  </content-header>
  <content-header title="获奖情况">
    <template slot="content">{{summaryInfo.winState}}</template>
  </content-header>
</div>
</template>

<script>
import ContentHeader from "@/components/ContentHeader";
export default {
  name: "SummaryIdea",
  components: { ContentHeader },
  props: ['summaryInfo']
};
</script>

<style scoped>

</style>
