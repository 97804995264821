import {Notification} from 'element-ui'

export const fail = (options)=>{
  return Notification({
    title: options.title || '失败',
    message: options.msg ||  '操作成功',
    type: 'error'
  })
}

export const success = (options)=>{
  return Notification({
    title: options.title || '成功',
    message: options.msg ||  '操作成功',
    type: 'success'
  })
}
