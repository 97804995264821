<template>
  <div class="my-requirement-management">
    <div class="table-operate flex-sb">
      <el-button type="primary" @click="$router.push({name: 'PublishRequirement'})">发布需求
      </el-button>
      <el-input
        clearable
        style="width: 220px;"
        placeholder="请输入需求名称"
        suffix-icon="el-icon-search"
        v-model="searchOptions.title"
      >
      </el-input>
    </div>
    <div class="table-content">
      <el-table
        :data="listData"
        style="width: 100%">
        <el-table-column
          width="160"
          show-overflow-tooltip
          label="标题"
          prop="title">
        </el-table-column>
        <el-table-column
          width="160"
          show-overflow-tooltip
          label="发布时间"
          prop="createTime">
        </el-table-column>
        <el-table-column
          width="200"
          label="应用领域"
          show-overflow-tooltip
          prop="appRange">
        </el-table-column>
        <el-table-column
          label="需求状态"
          width="100"
          prop="achievementStatus">
          <template slot-scope="scope">
            {{ dictionary.need_pub_needs_status[scope.row.needsStatus] }}
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="350">
          <template slot-scope="scope">
            <el-button size="mini"
                       @click="$router.push({name: 'RequirementShowDetail',query:{id: scope.row.id}})">查看
            </el-button>
                        <el-button size="mini" @click="$router.push({name: 'PublishRequirement',query:{row: scope.row}})">编辑</el-button>
            <el-popconfirm
              title="确定删除吗？"
              @confirm="confirmDelRequirement(scope.row.id)"
            >
              <el-button
                slot="reference"
                type="danger"
                style="margin-left: 4px;"
                size="mini">删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        :page-size="pageSize"
        :current-page.sync="pageNo"
        layout="prev, pager, next"
        :total="total"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { deleteRequirement, getDictByDicCode, getRequirementList } from '@/api/apiFunc'
import { dictionary } from '@/dictionary/dictionary'

export default {
  name: 'MyRequirementManagement',
  data () {
    return {
      dictionary: dictionary,
      searchOptions: {
        title: ''
      },
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listData: [],
      userId: this.$store.state.userInfo.userInfo.id
    }
  },
  watch: {
    searchOptions: {
      // immediate: true,
      deep: true,
      handler () {
        this.searchRequirement()
      }
    }
  },
  mounted () {
    getDictByDicCode('need_pub_trade_type').then(res => {
      res.data.unshift({ label: '不限', value: '' })
      this.tradeTypes = res.data
    })

    this.citys = dictionary.citys
    this.getPageList()
  },
  methods: {
    confirmDelRequirement (id) {
      deleteRequirement(id).then(res => {
        this.$myNotice.success({ msg: '删除成功' })
        this.getPageList()
      }).catch(err => {
        console.log(err)
      })
    },
    getPageList () {
      let options = Object.assign(
        {},
        this.searchOptions,
        { pageNo: this.pageNo, pageSize: this.pageSize, userId: this.userId })
      console.log('查询条件：', options)
      getRequirementList(options).then(res => {
        this.listData = res?.data?.result?.records || []
      }).catch(err => {
        console.log(err)
      })
    },
    searchRequirement () {
      this.pageNo = 1
      this.getPageList()
    },
    currentChange () {
      this.getPageList()
    },
  }
}
</script>

<style lang="scss">
.my-requirement-management {
  min-height: calc(100vh - 338px);

  .table-operate {
    padding-bottom: 10px;
  }

  .table-content {
    min-height: 600px;
  }

  .pagination {
    text-align: center;
    padding: 20px 0;
  }
}
</style>
