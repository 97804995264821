<template>
  <div class="publish-result">
    <div class="header-container">
      <div class="bread-list">
        <div class="top-search">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{name:'TheIndex'}">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{name:'SummaryShow'}">成果展示</el-breadcrumb-item>
            <el-breadcrumb-item>发布成果</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <el-divider class="top-divider"></el-divider>
      <div class="w1240">
        <el-steps :active="active"
                  class="step-class"
                  align-center
                  finish-status="success">
          <el-step title="基本信息"></el-step>
          <el-step title="成果创新"></el-step>
          <el-step title="竞争分析"></el-step>
        </el-steps>
        <el-form ref="form"
                 :model="form"
                 :rules="rules"
                 label-width="150px">
          <div v-show="active === 0">
            <el-form-item label="成果名称："
                          prop="title">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item label="联系人："
                              prop="name">
                  <el-input v-model="form.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话："
                              prop="phone">
                  <el-input v-model="form.phone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="研发时间："
                              prop="dateRange">
                  <el-date-picker type="datetimerange"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期"
                                  v-model="form.dateRange"
                                  value-format="yyyy-MM-dd HH:mm:ss"
                                  style="width: 100%;"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="发布日期："
                              prop="createTime">
                  <el-date-picker type="datetime"
                                  placeholder="选择发布日期"
                                  value-format="yyyy-MM-dd HH:mm:ss"
                                  v-model="form.createTime"
                                  style="width: 100%;"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="成果状态："
                              prop="achievementStatus">
                  <el-select v-model="form.achievementStatus"
                             placeholder="选择成果状态">
                    <el-option v-for="(item,index) in achievementStatus"
                               :key="index"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="合作方式："
                              prop="cooperateWay">
                  <el-input v-model="form.cooperateWay"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="合作价格："
                              prop="cooperateAmount">
                  <el-input v-model="form.cooperateAmount"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="行业分类："
                              prop="tradeType">
                  <el-select v-model="form.tradeType"
                             placeholder="选择行业类别">
                    <el-option v-for="(item,index1) in tradeTypes"
                               :key="index1"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所属类别："
                              prop="type">
                  <el-select v-model="form.type"
                             placeholder="选择所属类别">
                    <el-option v-for="(item,index2) in types"
                               :key="index2"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所属地区："
                              prop="city">
                  <el-select v-model="form.city"
                             placeholder="选择所属地区">
                    <el-option v-for="(item,index3) in citys"
                               :key="index3"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="专利种类："
                              prop="patentType">
                  <el-select v-model="form.patentType"
                             placeholder="选择专利种类">
                    <el-option v-for="(item,index4) in patentTypes"
                               :key="index4"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="专利号："
                              prop="patentNo">
                  <el-input v-model="form.patentNo"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="选择团队："
                          prop="groupId">
              <!--              <el-input v-model="form.groupId"></el-input>-->
              <el-select v-model="form.groupId"
                         placeholder="选择团队">
                <el-option v-for="(item,index5) in teams"
                           :key="index5"
                           :label="item.name"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="技术领域："
                          prop="domain">
              <el-input type="textarea"
                        maxlength="100"
                        show-word-limit
                        v-model="form.domain"></el-input>
            </el-form-item>
            <el-form-item label="关键字："
                          prop="keyword">
              <el-input type="textarea"
                        maxlength="50"
                        show-word-limit
                        v-model="form.keyword"></el-input>
            </el-form-item>
            <el-form-item label="成果描述："
                          prop="remark">
              <el-input type="textarea"
                        maxlength="120"
                        show-word-limit
                        v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item label="成果介绍："
                          prop="content">
              <el-input type="textarea"
                        maxlength="1000"
                        show-word-limit
                        v-model="form.content"></el-input>
            </el-form-item>
            <el-form-item label="知识产权归属："
                          prop="ownership">
              <el-input type="textarea"
                        maxlength="120"
                        show-word-limit
                        v-model="form.ownership"></el-input>
            </el-form-item>
            <el-col style="text-align: center;margin-bottom: 20px;">
              <el-button type="primary"
                         @click="active=1">下一步
              </el-button>
            </el-col>
          </div>
          <div v-show="active === 1">
            <el-form-item label="主要技术指标："
                          prop="mainQuota">
              <el-input type="textarea"
                        maxlength="1000"
                        show-word-limit
                        v-model="form.mainQuota"></el-input>
            </el-form-item>
            <el-form-item label="创新程度："
                          prop="innovativeness"
            >
              <el-input type="textarea"
                        maxlength="1000"
                        show-word-limit
                        v-model="form.innovativeness"></el-input>
            </el-form-item>
            <el-form-item label="获奖情况："
                          prop="winState"
            >
              <el-input type="textarea"
                        maxlength="1000"
                        show-word-limit
                        v-model="form.winState"></el-input>
            </el-form-item>
            <el-form-item label="应用范围："
                          prop="appRange"
            >
              <el-input type="textarea"
                        maxlength="1000"
                        show-word-limit
                        v-model="form.appRange"></el-input>
            </el-form-item>
            <el-form-item label="市场需求："
                          prop="marketNeeds">
              <el-input type="textarea"
                        maxlength="1000"
                        show-word-limit
                        v-model="form.marketNeeds"></el-input>
            </el-form-item>
            <el-form-item label="转移转化效益说明："
                          prop="benefitRemark">
              <el-input type="textarea"
                        maxlength="1000"
                        show-word-limit
                        v-model="form.benefitRemark"></el-input>
            </el-form-item>
            <el-col style="text-align: center;margin-bottom: 20px;">
              <el-button
                @click="active=0">上一步
              </el-button>
              <el-button type="primary"
                         @click="active=2">下一步
              </el-button>
            </el-col>
          </div>
          <div v-show="active === 2">
            <el-form-item label="全国竞争分析："
                          prop="nationwideAnalyse">
              <el-input type="textarea"
                        maxlength="1000"
                        show-word-limit
                        v-model="form.nationwideAnalyse"></el-input>
            </el-form-item>
            <el-form-item label="区域竞争分析："
                          prop="areaAnalyse">
              <el-input type="textarea"
                        maxlength="1000"
                        show-word-limit
                        v-model="form.areaAnalyse"></el-input>
            </el-form-item>
            <el-form-item label="行业竞争分析："
                          prop="professionAnalyse">
              <el-input type="textarea"
                        maxlength="1000"
                        show-word-limit
                        v-model="form.professionAnalyse"></el-input>
            </el-form-item>
            <el-form-item label="竞品信息："
                          prop="competingProduce">
              <el-input type="textarea"
                        maxlength="1000"
                        show-word-limit
                        v-model="form.competingProduce"></el-input>
            </el-form-item>
            <el-form-item label="展示图片：">
              <el-upload
                :on-success="onSuccessProduceImgs"
                :on-remove="onRemoveProduceImgs"
                list-type="picture-card"
                :file-list="produceImgs"
                :action="baseUrl + '/sys/common/upload'"
                :headers="headers"
                multiple>
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="商标图片：">
              <el-upload
                list-type="picture-card"
                :limit="3"
                :on-success="onSuccessTrademarkImgs"
                :on-remove="onRemoveTrademarkImgs"
                :action="baseUrl + '/sys/common/upload'"
                :headers="headers"
                :file-list="trademarkImgs"
                multiple>
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="附件上传：">
              <el-upload
                :limit="10"
                :on-success="onSuccessAttach"
                :on-remove="onRemoveAttach"
                :file-list="attachList"
                :action="baseUrl + '/sys/common/upload'"
                :headers="headers"
                multiple>
                <el-button size="small"
                           type="primary">点击上传
                </el-button>
                <!--                <div slot="tip"-->
                <!--                     class="el-upload__tip">只能上传jpg/png文件，且不超过300kb-->
                <!--                </div>-->
              </el-upload>
            </el-form-item>
            <el-col style="text-align: center;margin-bottom: 20px;">
              <el-button
                @click="active=1">上一步
              </el-button>
              <el-button type="primary"
                         @click='applyRequest'>提交申请
              </el-button>
            </el-col>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { addSummary, editSummary, getDictByDicCode, getTeamList, getTeams } from '@/api/apiFunc'
import { dictionary } from '@/dictionary/dictionary'
import { baseUrl } from '@/api/config'
import store from '@/store/index'
import { transformAttachFileList, transformImgsList } from '@/utils/unity_funcs'

export default {
  name: 'PublishResult',
  data () {
    return {
      rules: {
        title: [{ required: true, message: '请输入成果名称', trigger: 'blur' }],
        name: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        achievementStatus: [{ required: true, message: '请选择成果状态', trigger: 'change' }],
        tradeType: [{ required: true, message: '请选择行业分类', trigger: 'change' }],
        type: [{ required: true, message: '请选择所属类别', trigger: 'change' }],
        city: [{ required: true, message: '请选择所属地区', trigger: 'change' }],
        content: [{ required: true, message: '请输入成果介绍', trigger: 'blur' }],
        ownership: [{ required: true, message: '请输入知识产权归属', trigger: 'blur' }],
        mainQuota: [{ required: true, message: '请输入主要技术指标', trigger: 'blur' }],
        marketNeeds: [{ required: true, message: '请输入市场需求', trigger: 'blur' }],
        benefitRemark: [{ required: true, message: '请输入转移转化效益说明', trigger: 'blur' }],
      },
      headers: {
        'Authorization': store.state.userInfo.token || localStorage.getItem('token'),
        'X-Access-Token': store.state.userInfo.token || localStorage.getItem('token')
      },
      baseUrl: baseUrl,
      active: 0,
      achievementStatus: [],
      tradeTypes: [],
      types: [],
      citys: [],
      patentTypes: [],
      teams: [],
      form: {
        title: '',
        name: '',
        phone: '',
        dateRange: [],
        startTime: '',
        endTime: '',
        achievementStatus: '1',
        cooperateWay: '',
        cooperateAmount: '',
        tradeType: '',
        type: '',
        city: '',
        createTime: '',
        patentType: '',
        patentNo: '',
        groupId: '',
        domain: '',
        keyword: '',
        remark: '',
        content: '',
        ownership: '',
        mainQuota: '',
        innovativeness: '',
        winState: '',
        appRange: '',
        marketNeeds: '',
        benefitRemark: '',
        nationwideAnalyse: '',
        areaAnalyse: '',
        professionAnalyse: '',
        competingProduce: '',
        produceImgs: '',
        trademarkImgs: '',
        attachList: [],
      },
      produceImgs: [],
      trademarkImgs: [],
      attachList: []
    }
  },
  mounted () {
    this.getDic()
  },
  methods: {
    async getDic () {
      await getDictByDicCode('achievement_status').then(res => {
        this.achievementStatus = res.data
      })
      await getDictByDicCode('need_pub_trade_type').then(res => {
        this.tradeTypes = res.data
      })
      await getDictByDicCode('achievement_type').then(res => {
        this.types = res.data
      })
      await getDictByDicCode('achievement_patent_type').then(res => {
        this.patentTypes = res.data
      })
      await getTeams().then(res => {
        console.log(res.data.result)
      }).catch(err => {
        console.log(err)
      })
      this.citys = dictionary.citys.slice(1)
      await getTeamList().then(res => {
        this.teams = res.data.result.records
      })
      this.$nextTick(()=>{
        if (this.$route.query.row){
          console.log(this.$route.query.row)
          this.$route.query.row.achievementStatus = String(this.$route.query.row.achievementStatus)
          this.$route.query.row.patentType = String(this.$route.query.row.patentType)
          this.$route.query.row.type = String(this.$route.query.row.type)
          this.$route.query.row.tradeType = String(this.$route.query.row.tradeType)
          this.$route.query.row.dateRange = [this.$route.query.row.startTime,this.$route.query.row.endTime]
          this.form = this.$route.query.row
          this.attachList = transformAttachFileList(this.form.file)
          this.trademarkImgs = transformImgsList(this.form.trademarkImgs)
          this.produceImgs = transformImgsList(this.form.produceImgs)
        }
      })
    },
    applyRequest () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 处理图片路径存储格式
          this.handleArrToString()
          this.form.startTime = this.form.dateRange[0]
          this.form.endTime = this.form.dateRange[1]
          let reqMethod = this.form.id ? editSummary : addSummary
          this.form.userId = this.$store.state.userInfo.userInfo.id
          reqMethod(this.form).then(res => {
            if (res.data.code === 200) {
              this.$notify({
                title: '成功',
                message: '操作成功.',
                type: 'success'
              })
              this.$router.push({ name: 'SummaryShow' })
            }
          }).catch(err => {
            console.log(err)
          })
        }
      })
    },
    handleArrToString () {
      let produceImgsArr = this.produceImgs.map(item => {
        return item.message ? item.message : item.response && item.response.message
      })
      this.form.produceImgs = produceImgsArr.join(',')
      let trademarkImgsArr = this.trademarkImgs.map(item => {
        return item.message ? item.message : item.response && item.response.message
      })
      this.form.trademarkImgs = trademarkImgsArr.join(',')
    },
    onSuccessTrademarkImgs (response, file, fileList) {
      this.trademarkImgs = fileList
    },
    onRemoveTrademarkImgs (file, fileList) {
      this.trademarkImgs = fileList
    },
    onSuccessProduceImgs (response, file, fileList) {
      this.produceImgs = fileList
    },
    onRemoveProduceImgs (file, fileList) {
      this.produceImgs = fileList
    },
    onSuccessAttach (response, file, fileList) {
      this.form.file = fileList.map(item => {
        return {
          name: item.name,
          message: item.message ? item.message : item.response && item.response.message
        }
      })
      this.form.file = JSON.stringify(this.form.file)
    },
    onRemoveAttach (file, fileList) {
      this.form.file = fileList.map(item => {
        return {
          name: item.name,
          message: item.message && item.message || item.response && item.response.message
        }
      })
      this.form.file = JSON.stringify(this.form.file)
    },
  }
}
</script>
<style lang="scss">
.step-class {
  margin-bottom: 40px;
}

.publish-result {
  .el-select {
    width: 100%;
  }
}
</style>
