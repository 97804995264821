<template>
  <div class="requirement-show-detail">
    <div class="header-container">
      <div class="bread-list">
        <div class="top-search">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{name:'TheIndex'}">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{name:'CooperationRequirement'}">合作需求</el-breadcrumb-item>
            <el-breadcrumb-item>合作需求详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <el-divider class="top-divider"></el-divider>
      <requirement-detail-profile :detail="detail"></requirement-detail-profile>
      <div class="policy-content">
        <div class="w1240">
          <content-header title="需求详情">
            <template #content>
              {{detail.needContent}}
            </template>
          </content-header>
          <content-header title="应用领域">
            <template #content>
           {{detail.appRange}}
            </template>
          </content-header>
        </div>
        <el-tabs
          class="w1240"
          v-model="attachName">
          <el-tab-pane label="附件下载"
                       name="attachDownload">
            <attach-files-list :fileList="detail.file"></attach-files-list>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'
import AttachFilesList from '@/components/AttachFilesList'
import RequirementDetailProfile from '@/components/RequirementDetailProfile'
import { getRequirementDetail } from '@/api/apiFunc'

export default {
  name: 'summaryShowDetail',
  components: { AttachFilesList, RequirementDetailProfile, ContentHeader },
  data () {
    return {
      attachName: 'attachDownload',
      detail: {},
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      getRequirementDetail(this.$route.query.id).then(res => {
        this.detail = res.data.result
      }).catch(err => {
        console.log(err)
      })
    },
  }
}
</script>

<style lang="scss">
.requirement-show-detail {
  background: #fff;

  .policy-content {
    background: #F4F5F7;
    padding-top: 30px;
  }

  .header-container {
    .top-search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 0 24px 0;
    }

    .bread-list {
      width: 1240px;
      margin: 0 auto;
    }
  }
}
</style>
