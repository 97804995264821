import { baseUrl } from '@/api/config'

export function transformAttachFileList (originFile) {
  if (originFile === ""){
    return []
  }
  let fileArr = JSON.parse(originFile), urlArr = []
  urlArr = fileArr.map(file => {
    return {
      name: file.name,
      url: baseUrl + file.message,
      message: file.message,
    }
  })
  return urlArr
}

export function transformImgsList (originFile) {
  if (originFile === ""){
    return []
  }
  let imgArr = originFile.split(','), imgJson = []
  imgArr.forEach((img) => {
    imgJson.push({
      name: img,
      url: baseUrl + img,
      message: img
    })
  })
  return imgJson
}
