<template>
  <div class="policy-rules">
    <div class="header-container">
      <div class="bread-list">
        <div class="top-search">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{name:'TheIndex'}">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{name: 'ActiveNews'}">活动新闻</el-breadcrumb-item>
            <el-breadcrumb-item>活动详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <el-divider class="top-divider"></el-divider>
      <div class="w1240 header df"
           style="padding-bottom: 40px;">
        <img :src="baseUrl + detail.headUrl"
             style="width: 412px;height: 280px;border: 1px solid #eee;margin-right: 40px"
             alt="活动图片">
        <div class="profile-container flex-column space-between"
             style="flex: 1">
          <div class="flex-sb"
               style="align-items: flex-start">
            <h3 class="title"
                style="margin-top: 0;">{{ detail.title }}</h3>
            <el-tag v-if="+new Date(detail.endTime) - +new Date() > 0"
                    effect="plain"
                    type="success">进行中
            </el-tag>
            <el-tag v-if="+new Date(detail.endTime) - +new Date() < 0"
                    effect="plain"
                    type="danger">已结束
            </el-tag>
          </div>
          <p class="progress">活动时间：{{ detail.startTime }} 至 {{ detail.endTime }}</p>
          <p class="progress">活动地点：{{ detail.address }}</p>
          <div class="profile-contact flex-sb">
            <el-button style="width: 255px;height: 58px;font-size: 20px;margin-top: 60px"
                       type="primary"
                       v-is-login
                       v-if="+new Date(detail.endTime) - +new Date() > 0 && detail.isBaoming == 0"
                       @click="baoMing"
            >报名参加
            </el-button>
            <el-button style="width: 255px;height: 58px;font-size: 20px"
                       v-if="detail.isBaoming == 1"
                       v-is-login
                       type="primary">已报名
            </el-button>
            <el-button style="width: 255px;height: 58px;font-size: 20px"
                       v-if="+new Date(detail.endTime) - +new Date() < 0"
                       type="primary">活动已结束
            </el-button>
          </div>
        </div>
      </div>
      <div class="result-active-content">
        <div class="w1240 flex-column">
          <content-header title="活动简介">
            <template #content>
              {{ detail.description }}
            </template>
          </content-header>
          <content-header title="活动详情">
            <template #content>
              <div v-html="detail.content"></div>
            </template>
          </content-header>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'
import { baomingActivity, getActiveDetail } from '@/api/apiFunc'
import { baseUrl } from '@/api/config'

export default {
  name: 'ResultActive',
  components: { ContentHeader },
  data () {
    return {
      detail: {},
      baseUrl: baseUrl
    }
  },
  mounted () {
    this.initPage()
  },
  methods: {
    initPage () {
      getActiveDetail(this.$route.query.id).then(res => {
        this.detail = res.data.result
      })
    },
    baoMing () {
      baomingActivity({
        activitiesId: this.$route.query.id,
        userId: this.$store.state.userInfo.userInfo.id
      }).then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.$notify({
            title: '成功',
            message: '报名成功.',
            type: 'success'
          })
          this.initPage()
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss">
.policy-rules {
  background: #fff;

  .result-active-content {
    background: #FAFAFA;
    padding-top: 50px;
  }

  .header-container {
    .top-search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin-bottom: 20px;
      padding: 18px 0 24px 0;
    }

    .bread-list {
      width: 1240px;
      margin: 0 auto;
    }
  }

  .full-container {
    border-bottom: 1px solid #eeeeee;
  }

  .page-size-class {
    //background: #F5F5F5;
    padding-bottom: 76px;
    padding-top: 40px;

    div {
      width: 1240px;
      margin: 0 auto;
      text-align: center;
      //background: #F5F5F5;
    }
  }

  .el-pagination.is-background .el-pager li {
    background: #ffffff;

  }

  .news-list-item {
    width: 1240px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;;

    .news-desc {
      width: 736px;
      margin-left: 60px;
    }

    img {
      width: 426px;
      height: 267px;

      &:hover {
        cursor: pointer;
      }
    }

    h3 {
      margin: 0;
      font-size: 24px;
      padding-bottom: 30px;

      &:hover {
        cursor: pointer;
        color: #E68140
      }
    }

    p {
      margin: 0;
      font-size: 24px;
      padding-bottom: 30px;

      &:hover {
        cursor: pointer;
        color: #E68140
      }
    }

    span {
      font-size: 24px;
    }
  }

  .news-tab {
    width: 1240px;
    margin: 0 auto;
    font-size: 20px;
    margin-top: 20px;

    span {
      margin-left: 58px;

      &:hover {
        cursor: pointer;
      }

      &:nth-of-type(2) {
        color: #ffffff;
        padding: 8px 30px;
        background: #E1660D;
      }

      &:nth-of-type(1):hover {
        color: #E1660D;
      }
    }
  }

  .w1240 {
    width: 1240px;
    margin: 0 auto;
  }

  .result-room {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .result-room-item {
      width: 286px;
      height: 286px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0px 2px 2px 0px rgba(8, 1, 3, 0.13);

      &:hover {
        cursor: pointer;
        /* &:before{
           text-align: center;
           line-height: 286px;
           color: #ffffff;
           font-size: 14px;
           font-weight: 700;
           content: "行业分类 | 合作方式 | 联系企业";
           display: block;
           position: absolute;
           top: 0;
           left: 0;
           right: 0;
           bottom: 0;
           background: #E68140;
           opacity: 0.7;
         }*/
      }

      img {
        width: 286px;
      }

      div {
        background: #ffffff;
        font-size: 16px;
        color: #232326;
        width: 100%;
        text-align: center;
        padding: 12px 0;

        &:hover {
          cursor: pointer;
          color: #E68140;
        }
      }
    }

  }

  .avatar-container {
    margin-top: 4px;
    margin-bottom: 8px;

    .result-room-item {
      font-size: 0;

      &:hover {
        cursor: pointer;
      }

      background: #ffffff;

      img {
        width: 146px;
        height: 146px;
        margin-top: 20px;
      }

      .profession {
        font-size: 20px;
        color: #000000;
        padding: 0;
        padding-top: 28px;
      }

      .profession-line {
        width: 26px;
        height: 5px;
        background: #E68140;
        padding: 0;
        margin-bottom: 15px;
        margin-top: 6px;
      }

      .profession-role {
        font-size: 16px;
        color: #000000;
        padding: 0;
      }
    }
  }
}
</style>
