<template>
  <div class="expert-detail">
    <div class="header-container">
      <div class="bread-list">
        <div class="top-search">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{name:'TheIndex'}">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{name:'ProfessionalPool'}">专家库</el-breadcrumb-item>
            <el-breadcrumb-item>专家详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <el-divider class="top-divider"></el-divider>
      <div class="w1240 top-container flex-column align-center">
        <img class="head-avatar"
             :src="(detail.headUrl ? (baseUrl + detail.headUrl) : defaultAvatar)"
             alt="">
        <span class="name">{{ detail.name }}</span>
        <div>
          <span>{{ detail.company }}</span>
          <el-divider direction="vertical"></el-divider>
          <span>{{ detail.domain }}</span>
          <el-divider direction="vertical"></el-divider>
          <span>{{ detail.post }}</span>
          <el-divider direction="vertical"></el-divider>
          <span>{{ detail.positionalTitles }}</span>
        </div>
        <!--          TODO:联系按钮？-->
        <el-button type="primary"
                   style="width: 184px;height: 45px;margin: 18px 0;font-size: 18px" @click="dialogCard = true">联系方式
        </el-button>
<!--        <div class="read-num">-->
<!--          <i class="el-icon-view"></i>-->
<!--          &lt;!&ndash;          TODO:联系人数统计？&ndash;&gt;-->
<!--          <span>123人联系</span>-->
<!--        </div>-->
      </div>
      <div class="w1240">
        <content-header title="概况">
          <template #content>
            {{ detail.remark }}
          </template>
        </content-header>
        <content-header title="代表成果">
          <template #content>
            {{ detail.achievement }}
          </template>
        </content-header>
        <content-header title="教学工作">
          <template #content>
            {{ detail.workContent }}
          </template>
        </content-header>
        <content-header title="研究领域">
          <template #content>
            {{ detail.domainContent }}
          </template>
        </content-header>
        <content-header title="大事记">
          <template #content>
            {{ detail.memoir }}
          </template>
        </content-header>
        <content-header title="论文">
          <template #content>
            {{ detail.thesis }}
          </template>
        </content-header>
      </div>
    </div>
    <el-dialog title="名片"
               class="private-card"
               width="400px"
               :visible.sync="dialogCard">
      <div class="card df align-center">
        <div class="df flex-column align-center" style="margin-top: 24px;">
          <img class="head-avatar"
               :src="(detail.headUrl ? (baseUrl + detail.headUrl) : defaultAvatar)"
               alt="">
          <span class="name">{{ detail.name }}</span>
        </div>
        <div class="info">
          <div>
            <span>{{ detail.company }}</span>
            <el-divider direction="vertical"></el-divider>
            <span>{{ detail.domain }}</span>
          </div>
          <div>
            <span>{{ detail.post }}</span>
            <el-divider direction="vertical"></el-divider>
            <span>{{ detail.positionalTitles }}</span>
          </div>
          <div class="df flex-column">
            <span class="title">电话</span>
            <span>{{detail.phone}}</span>
          </div>
          <div class="df flex-column">
            <span class="title">邮箱</span>
            <span>{{detail.email}}</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'
import { getProfessionalDetail } from '@/api/apiFunc'
import { baseUrl } from '@/api/config'

export default {
  name: 'ProfessionalDetail',
  components: { ContentHeader },
  data () {
    return {
      defaultAvatar: require('@/assets/images/default_experts_avatar.png'),
      dialogCard: false,
      baseUrl: baseUrl,
      detail: {
        name: '',
        company: '',
        domain: '',
        post: '',
        positionalTitles: '',
      }
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    /**
     * 获取专家详情
     */
    getDetail () {
      getProfessionalDetail(this.$route.query.id).then(res => {
        if (res?.data?.result) {
          this.detail = res?.data?.result
        }
      }).catch(err => {
        console.log(err)
      })
    },
  }
}
</script>

<style lang="scss">
.expert-detail {
  background: #fff;


  .header-container {
    .top-search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin-bottom: 20px;
      padding: 18px 0 24px 0;
    }

    .bread-list {
      width: 1240px;
      margin: 0 auto;
    }
  }

  .top-container {
    margin-bottom: 54px;
  }

  .full-container {
    border-bottom: 1px solid #eeeeee;
  }

  .head-avatar {
    width: 175px;
    height: 175px;
    //background: #bfa;
    border-radius: 50%;
  }

  .name {
    font-size: 34px;
    font-weight: 700;
    margin: 20px 0 34px 0;
  }

  .read-num {
    color: #8b8b8b;
  }
  .card{
    //width: 100%;
    border-bottom: 4px solid #e68140;
    //padding: 20px 0 20px 20px;
  }
  .private-card{
    .card{
      padding-left: 40px;
    }
    .el-dialog__header{
      display: none;
    }
    .el-dialog__body{
      padding: 0;
      .head-avatar{
        width: 80px;
        height: 80px;
      }
      .name{
        font-size: 16px;
      }
      .info{
        //margin-left: 20px;
        flex: 1;
        border-left: 1px solid #eee;
        padding-left: 10px;
        margin-left: 40px;
        padding-top: 20px;
        .title{
          color: #8b8b8b;
          margin-bottom: 4px;
        }
        >div{
          font-size: 14px;
          margin-bottom: 18px;
        }
      }
    }
  }
}
</style>
