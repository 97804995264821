<template>
<div>
  <div class="full-container" v-for="(item) in listData" :key="item.id">
    <div class="news-list-item"
         @click="$router.push({name: 'NewsDetail',query: {id:item.id}})">
<!--      TODO:图片字段没有？-->
      <div><img :src="baseAssetsUrl + item.headUrl"
                alt=""></div>
      <div class="news-desc">
        <h3>{{item.title}}</h3>
        <div class="n-active-profile df"><span class="label40">新闻简介: </span><span>{{item.descrption}}</span></div>
        <span>{{item.createTime}}</span>
      </div>
    </div>
  </div>
  <div class="page-size-class">
    <div>
      <el-pagination
        background
        :current-page="pageNo"
        layout="prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</div>
</template>

<script>
import { baseAssetsUrl } from '@/api/config'

export default {
  name: "NewsList",
  props:['pageNo','total','listData'],
  data(){
    return {
      baseAssetsUrl: baseAssetsUrl,
    }
  }
};
</script>
<style lang="scss">
.n-active-profile{
  font-size: 20px;
  margin-bottom: 30px;
  height: 120px;
  max-height: 120px;
  overflow: hidden;
}
.label40{
  min-width: 100px;
  margin-right: 10px;

}
.full-container{
  padding: 20px 0;
}
</style>
