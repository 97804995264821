<template>
  <div class="user-login-page">
    <!--    <div class="back-home">-->
    <!--      <el-button type="text" @click="goLogin" style="color: #ffffff;font-size: 16px">返回首页</el-button>-->
    <!--    </div>-->
    <div class="welcome-title flex-c-c">
      <img src="@/assets/images/login-logo.png"
           style="width: 50px;height: 50px"
           alt="">
      <p>基于大数据科技孵化成果转移转化平台</p>
    </div>
    <div class="login-content">
      <div class="login-form">
        <div class="top-tab df">
          <span :class="tabName !== 'login' ? 'is-active' : ''"
                @click="tabName = 'login'">登录账号</span>
          <span :class="tabName !== 'register' ? 'is-active' : ''"
                @click="tabName = 'register'">注册账号</span>
        </div>
        <el-form v-if="tabName === 'login'"
                 class="form"
                 label-position="left"
                 ref="form"
                 :model="form"
                 label-width="80px">
          <el-form-item label="手机号">
            <el-input
              placeholder="手机号"
              prefix-icon="el-icon-user-solid"
              v-model="form.username">
            </el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input
              type="password"
              placeholder="密码"
              prefix-icon="el-icon-key"
              v-model="form.password">
            </el-input>
          </el-form-item>
          <el-form-item label="验证码">
            <div class="df">
              <el-input
                placeholder="输入验证码"
                @keyup.enter.native="enterEvents"
                v-model="form.captcha">
              </el-input>
              <img style="cursor:pointer;"
                   :src="captchaUrl"
                   @click="refreshCaptcha"
                   alt="验证码">
            </div>
          </el-form-item>
          <div class="flex-column">
            <el-button type="primary"
                       @click="goLogin"
                       :loading="loading"
                       class="login-button">登录
            </el-button>
            <div @click="tabName = 'register'"
                 class="flex-sb">
              <div><span class="sub-desc">还没有账号？</span><span class="register-btn">注册账号</span></div>
              <div class="visitor"
                   @click="$router.push({name: 'TheIndex'})">游客访问
              </div>
            </div>
          </div>
        </el-form>
        <template v-if="tabName === 'register'">
          <el-form class="form"
                   label-position="left"
                   ref="registerForm"
                   :model="registerForm"
                   label-width="80px">
            <el-form-item label="手机号">
              <el-input
                placeholder="手机号"
                prefix-icon="el-icon-user-solid"
                v-model="registerForm.username">
              </el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input
                type="password"
                placeholder="密码"
                prefix-icon="el-icon-key"
                v-model="registerForm.password">
              </el-input>
            </el-form-item>
            <el-form-item label="确认密码">
              <el-input
                type="password"
                placeholder="确认密码"
                prefix-icon="el-icon-key"
                v-model="registerForm.repeatPwd">
              </el-input>
            </el-form-item>
            <el-form-item label="验证码">
              <div class="df">
                <el-input
                  placeholder="输入验证码"
                  prefix-icon="el-icon-key"
                  v-model="registerForm.captcha">
                </el-input>
                <img style="cursor:pointer;"
                     :src="captchaUrl"
                     @click="refreshCaptcha"
                     alt="验证码">
              </div>
            </el-form-item>
            <div class="flex-c-c btn-row">
              <el-button type="primary"
                         style="width: 50%"
                         @click="handleRegister">注册
              </el-button>
              <el-button type="primary"
                         plain
                         style="width: 50%"
                         @click="tabName = 'login'">返回登录
              </el-button>
            </div>
          </el-form>
        </template>
      </div>
    </div>
    <div class="bottom-info">
      <span>联系我们：四川省自然资源科学研究院（四川省生产力促进中心）  028-68107435  </span>
      <span>主办方：成都中企艾维数据有限公司</span>
      <span>电话：028-65215025</span>
    </div>
  </div>
</template>
<script>
import { getVerifyCode, login, register } from '@/api/apiFunc'

export default {
  name: 'UserLoginPage',
  data () {
    return {
      loading: false,
      captchaUrl: '',
      form: {
        username: '',
        password: '',
        captcha: ''
      },
      tabName: 'login',
      registerCaptchaUrl: '',
      registerForm: {
        username: '',
        password: '',
        repeatPwd: '',
        captcha: '',
      }
    }
  },
  mounted () {
    this.refreshCaptcha()
    this.refreshRegCaptcha()
    if (this.$route.query.tabName) {
      this.tabName = this.$route.query.tabName
    }
  },
  methods: {
    enterEvents (e) {
      if (e.keyCode !== 13) {
        return
      }
      this.goLogin()
    },
    refreshCaptcha () {
      getVerifyCode().then(res => {
        this.captchaUrl = res.data.result
      }).catch(err => {
        console.log(err)
      })
    },
    goLogin () {
      this.loading = true;
      // this.$router.push({ name: 'TheIndex' })
      let reqData = {
        'captcha': this.form.captcha,
        'checkKey': 'abc123456',
        'password': this.form.password,
        'username': this.form.username
      }
      login(reqData).then(res => {
        this.loading = false;
        if (res.data.code === 200) {
          this.$store.commit('SET_USER_INFO', res.data.result)
          this.$router.push({ name: 'TheIndex' })
        }
        if (res.data.code === 412) {
          this.refreshCaptcha()
        }
      }).catch(error => {
        this.loading = false;
        console.log(error)
      })

    },
    refreshRegCaptcha () {
      getVerifyCode().then(res => {
        this.registerCaptchaUrl = res.data.result
      }).catch(err => {
        console.log(err)
      })
    },
    handleRegister () {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          let reqData = {
            'captcha': this.registerForm.captcha,
            'checkKey': 'abc123456',
            'password': this.registerForm.password,
            'username': this.registerForm.username
          }
          register(reqData).then(res => {
            if (res.data.code === 200) {
              this.$notify({
                title: '成功',
                message: '注册成功.',
                type: 'success'
              })
            }
            if (res.data.code === 412) {
              this.refreshRegCaptcha()
            }
          }).catch(err => {
            console.log(err)
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
.user-login-page {
  .el-form-item{
    margin-bottom: 34px;
  }
  .welcome-title{
    padding-top: 20px;
    margin-top: 0;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: #4C9EEA;
  }

  .back-home {
    text-align: right;
    padding-right: 10px;
  }

  //width: 100%;
  height: 100%;
  background: url("../assets/images/user-login.png") no-repeat center;
  background-size: cover;

  .login-content {
    width: 600px;
    margin: 0 auto;
    //padding-top: 46px;

    .login-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fff;

      > img {
        height: 64px;
      }

      > p {
        font-size: 30px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }

    .form {
      width: 600px;
      padding: 20px 60px 60px 60px;
      box-sizing: border-box;
      //background: #fff;

      .login-button {
        width: 100%;
        margin-bottom: 10px;

        &:hover {
          cursor: pointer;
        }
      }
    }


  }

  .register-btn {
    padding-top: 10px;
    text-align: left;
    color: #eb9a66;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.bottom-info {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 15px 0;
  align-items: center;
  font-size: 16px;
  background: #000;
  color: #fff;

  span {
    margin-right: 40px;
  }
}

.sub-desc {
  color: #8b8b8b;
}

.visitor {
  color: #8b8b8b;
  font-size: 12px;

  &:hover {
    cursor: pointer;
  }
}

.top-tab {
  width: 100%;
  border-bottom: 1px solid #eee;

  span {
    display: inline-block;
    width: 50%;
    text-align: center;
    padding: 20px 0;

    &:hover {
      cursor: pointer;
    }
  }

  .is-active {
    background: #eee;
  }

}
</style>
