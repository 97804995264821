<template>
  <div>
    <content-header title="研发时间">
      <template slot="content">
        {{ summaryInfo.startTime }} 至 {{ summaryInfo.endTime }}
      </template>
    </content-header>
    <content-header title="发布时间">
      <template slot="content">
        {{summaryInfo.createTime}}
      </template>
    </content-header>
    <content-header title="行业类别">
      <template slot="content">
        {{ dictionary.tradeTypes[summaryInfo.tradeType] }}
      </template>
    </content-header>
    <content-header title="所属类别">
      <template slot="content">
        {{ dictionary.belongTypes[summaryInfo.type] }}
      </template>
    </content-header>
    <content-header title="技术领域">
      <template slot="content">
        {{ summaryInfo.domain }}
      </template>
    </content-header>
    <content-header title="关键字">
      <template slot="content">
        {{summaryInfo.keyword}}
      </template>
    </content-header>
    <content-header title="成果介绍">
      <template slot="content">
        {{summaryInfo.remark}}
      </template>
    </content-header>
    <content-header title="主要技术指标">
      <template slot="content">
        {{summaryInfo.mainQuota}}
      </template>
    </content-header>
  </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'
import { dictionary } from '@/dictionary/dictionary'

export default {
  name: 'summaryDetailDesc',
  components: { ContentHeader },
  props: ['summaryInfo'],
  data() {
    return {
      dictionary: dictionary
    }
  }
}
</script>

<style scoped>

</style>
