import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import directive from "@/directive/directive"
import 'normalize.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../theme/index.css'
import '@/styles/_diy-elementui.scss'
import store  from '@/store'
import * as notice from "@/utils/notice"
Vue.config.productionTip = false;
import locale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(ElementUI,{ locale });
Vue.prototype.$myNotice = notice
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
// export const detectZoom = () => {
//   let ratio = 0,
//     screen = window.screen,
//     ua = navigator.userAgent.toLowerCase();
//   if (window.devicePixelRatio !== undefined) {
//     ratio = window.devicePixelRatio;
//   } else if (~ua.indexOf('msie')) {
//     if (screen.deviceXDPI && screen.logicalXDPI) {
//       ratio = screen.deviceXDPI / screen.logicalXDPI;
//     }
//   } else if (
//     window.outerWidth !== undefined &&
//     window.innerWidth !== undefined
//   ) {
//     ratio = window.outerWidth / window.innerWidth;
//   }
//   if (ratio) {
//     ratio = Math.round(ratio * 100);
//   }
//   return ratio;
// };
//
// document.body.style.zoom = 100 / Number(detectZoom);
