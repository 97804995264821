<template>
  <div>
    <div class="full-container"
         v-for="(item) in listData"
         :key="item.id">
      <div class="news-list-item"
           style="padding-top: 20px;"
           @click="goDetail(item.id)">
        <div><img :src="item.headUrl"
                  alt=""></div>
        <div class="news-desc flex-column space-between">
          <div class="flex-sb flex-start">
            <h3>{{ item.title }}</h3>
            <el-tag v-if="+new Date(item.endTime) - +new Date() > 0"
                    effect="plain"
                    type="success">进行中
            </el-tag>
            <el-tag v-if="+new Date(item.endTime) - +new Date() < 0"
                    effect="plain"
                    type="danger">已结束
            </el-tag>
          </div>
          <div class="active-time df">
            <span class="label">活动时间：</span><span>{{ item.startTime }} 至 {{ item.endTime }}</span></div>
          <div class="active-profile"><span class="label">活动简介: </span><span class="text-cut-2">{{
              item.description
            }}</span>
          </div>
          <el-button type="primary"
                     v-is-login
                     style="width: 98px;height: 40px;"
                     @click.stop="baoMing(item.id)"
                     v-if="(+new Date(item.endTime) - +new Date()) > 0 && item.isBaoming == 0">报名参加
          </el-button>
          <el-button type="success"
                     size="small"
                     v-is-login
                     v-if="item.isBaoming == 1"
                     style="width: 98px;height: 40px">已报名
          </el-button>
        </div>
      </div>
    </div>
    <!--    <div class="full-container">
          <div class="news-list-item"
               style="padding-top: 20px;"
               @click="$router.push({name: 'ResultActive'})">
            <div><img src="../assets/images/new1.png"
                      alt=""></div>
            <div class="news-desc">
              <div class="flex-sb flex-start">
                <h3>全国第一个线下全产业技术展示馆启动，展示项目征集中!</h3>
                <el-tag type="danger">已结束</el-tag>
              </div>
              <div class="active-time df"><span class="label">活动时间：</span><span>2019年11月11日</span></div>
              <div class="active-profile"><span class="label">活动简介:</span><span>首期产业技术馆展示技术全面征集中，如果您的技术有足够市场容量和推广价值有足够技术亮点，如果您想加大技术的推广力度，获得更多技术报道以及曝光率，请及时联系我们。</span>
              </div>
              <el-button type="success"
                         size="small"
                         style="width: 98px;height: 40px">已报名
              </el-button>
            </div>
          </div>
        </div>-->
    <div class="page-size-class">
      <div>
        <el-pagination
          background
          :current-page="pageNo"
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { baseAssetsUrl,baseUrl } from '@/api/config'
import { baomingActivity } from '@/api/apiFunc'

export default {
  name: 'ActiveList',
  props: ['pageNo', 'total', 'listData'],
  data () {
    return {
      baseAssetsUrl: baseAssetsUrl,
      baseUrl: baseUrl,
      userId: this.$store.state.userInfo.userInfo.id
    }
  },
  methods: {
    goDetail (id) {
      this.$router.push({ name: 'ResultActive', query: { id: id } })
    },
    baoMing (id) {
      baomingActivity({ activitiesId: id ,userId: this.userId}).then(res => {
        console.log(res)
        if(res.data.code === 200){
          this.$notify({
            title: '成功',
            message: '报名成功.',
            type: 'success'
          });
          this.$emit('refresh')
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss">
.news-list-item {
  width: 1240px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  //;

  .news-desc {
    width: 736px;
    margin-left: 60px;

    h3 {
      max-width: 700px;
    }
  }

  img {
    width: 426px;
    height: 267px;

    &:hover {
      cursor: pointer;
    }
  }

  h3 {
    margin: 0;
    font-size: 30px;
    padding-bottom: 30px;

    &:hover {
      cursor: pointer;
      color: #E68140
    }
  }

  p {
    margin: 0;
    //font-size: 24px;
    padding-bottom: 30px;

    &:hover {
      cursor: pointer;
      color: #E68140
    }
  }

}

.tab-container {
  padding: 20px 0;
}

.news-tab {
  width: 1240px;
  margin: 0 auto;
  font-size: 20px;

  span {
    margin-left: 58px;

    &:hover {
      cursor: pointer;
    }
  }

  .is-active {
    border-radius: 5px;
    color: #ffffff;
    padding: 8px 30px;
    background: #E1660D;
  }
}

.flex-start {
  align-items: flex-start;
}

.active-time {
  font-size: 16px;
  margin-bottom: 30px;

  .label {
    min-width: 80px;
    margin-right: 10px;
  }
}

.active-profile {
  display: flex;
  margin-bottom: 30px;
  //height: 48px;
  max-height: 48px;
  overflow: hidden;

  .label {
    min-width: 80px;
    margin-right: 10px;
  }
}
</style>
