<template>
<div class="summary-idea">
  <content-header title="应用范围">
    <template slot="content">{{summaryInfo.appRange}}</template>
  </content-header>
  <content-header title="市场需求">
    <template slot="content">{{summaryInfo.marketNeeds}}</template>
  </content-header>
<!--  <content-header title="转化转移效益">-->
<!--    <template slot="content">说明</template>-->
<!--  </content-header>-->
</div>
</template>

<script>
import ContentHeader from "@/components/ContentHeader";
export default {
  name: "ApplyZone",
  components: { ContentHeader },
  props: ['summaryInfo']
};
</script>

<style scoped>

</style>
