// const baseUrl = 'http://114.115.176.252:8081';
// const baseAssetsUrl = 'http://114.115.176.252:8081/sys/common/static'
const baseUrl = 'http://211.149.208.39:8080/jeecgboot';
const baseAssetsUrl = 'http://211.149.208.39:8080/jeecgboot/sys/common/static'
const URL_MAP = {
  'dev': {
    baseUrl: 'http://114.115.176.252:8081',
    baseAssetsUrl: 'http://114.115.176.252:8081/sys/common/static'
  },
  'resourceLab': {
    baseUrl: 'http://211.149.208.39:8080/jeecgboot',
    baseAssetsUrl: 'http://211.149.208.39:8080/jeecgboot/sys/common/static'
  },
}
export {
  baseUrl,
  baseAssetsUrl
}
