import axios from 'axios'
import router from '@/router/index'
import { baseUrl } from '@/api/config'
import store from '@/store/index'
import { Notification } from 'element-ui'

const instance = axios.create({
  baseURL: baseUrl,
  // headers: {
  //   'X-Access-Token': store.state.userInfo.token
  // },
  timeout: 30000,
  // transformRequest: [function(data, headers) {
  //   // 对发送的 data 进行任意转换处理
  //   return data;
  // }],
  // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
  transformResponse: [function (data) {
    // 对接收的 data 进行任意转换处理
    if (typeof data === 'string') {
      return JSON.parse(data)
    }
    return data
  }]
})

export const getFunc = (options) => {
  return instance.get(options.url, {
    params: options.data
  })
}

export const postFunc = (options) => {
  return instance.post(options.url, options.data)
}

export const delFunc = (options) => {
  return instance.delete(options.url, options.data)
}
export const putFunc = (options) => {
  return instance.put(options.url, options.data)
}
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token')
  config.headers['Authorization'] = store?.state?.userInfo?.token || token
  config.headers['X-Access-Token'] = store?.state?.userInfo?.token || token
  // 在发送请求之前做些什么
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  if (process.env.NODE_ENV === 'development') {
    console.log(response)
  }
  let resCode = response.data.code;
  if (resCode === 401) {
    Notification({
      title: '失败',
      message: 'token过期，请重新登录.',
      type: 'error'
    })
    toLogin()
    // router.replace({ name: 'UserLoginPage' })
    // store.commit('CLEAR_USER_INFO')
    // window.abort()
  }
  if (resCode === 412) {
    Notification({
      title: '失败',
      message: response.data.message,
      type: 'error'
    })
  }
  if (resCode === 500) {
    Notification({
      title: '失败',
      message: response.data.message,
      type: 'error'
    })
    toLogin()
    // router.replace({ name: 'UserLoginPage' })
    // store.commit('CLEAR_USER_INFO')
    // window.abort()
  }
  if (resCode === 510) {
    Notification({
      title: '失败',
      message: '没有权限.',
      type: 'error'
    })
    // router.replace({name: 'UserLoginPage'})
    // store.commit('CLEAR_USER_INFO')
    // window.abort()
  }
  return response
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})

const errorMsgMap = {
  401: 'token过期，请重新登录.',
  510: '没有权限.',
}

function toLogin(){
  router.replace({ name: 'UserLoginPage' })
  store.commit('CLEAR_USER_INFO')
  // window.abort()
}
