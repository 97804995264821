<template>
  <div class="card-list">
    <div class="card-list-item"
         v-for="(listItem,index) in listData"
         :key="index"
         @click="clickItem(listItem)">
      <div class="top-content">
        <img :src="baseAssetsUrl + (routeName === 'SummaryShowDetail' ?  listItem.produceImgs.split(',')[0] : listItem.imgs.split(',')[0])"
             alt="">
      </div>
      <div class="bottom-content">
        <div class="title text-cut-2">{{ listItem.title }}</div>
        <div class="city-desc">{{ listItem.city }}/{{ dictionary.tradeTypes[listItem.tradeType] }}</div>
        <div class="bottom-content-info">
          <div>
            <i class="el-icon-date"></i>
            <span>{{ listItem.createTime }}</span>
          </div>
          <div>
            <i class="el-icon-view"></i>
            <span>{{ listItem.seeNum || 0 }}人浏览</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseAssetsUrl, baseUrl } from '@/api/config'
import { dictionary } from '@/dictionary/dictionary'

export default {
  name: "CardItem",
  props: {
    routeName: {
      type: String,
      default: ""
    },
    "listData": {
      type: Array,
      default: () => []
    }
  }
  ,
  data () {
    return {
      baseAssetsUrl: baseAssetsUrl,
      dictionary: dictionary
    };
  },
  methods: {
    clickItem (listItem) {
      this.$router.push({ name: this.routeName, query: {
          id: listItem.id
        } });
    }
  }
};
</script>

<style lang="scss"
       scoped>
@import "@/styles/_variables.scss";

.card-list {
  width: 1240px;
  padding-top: 38px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;

  .card-list-item {
    display: flex;
    flex-direction: column;
    width: 288px;
    min-height: 290px;
    margin-bottom: 60px;
    margin-right: 22px;

    &:hover {
      cursor: pointer;
      box-shadow: 6px 4px 8px 2px #eee;
    }

    .top-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 100%;
        height: 170px;
      }

      span {
        font-size: 14px;
        color: #8B8B8B;
        margin-top: 8px;
      }
    }

    .bottom-content {
      background: #fff;
      display: flex;
      flex-direction: column;

      .title {
        padding-left: 24px;
        padding-right: 22px;
        font-size: 16px;
        color: #212121;
        margin: 8px 0 30px 0;
        height: 38px;

        &:hover {
          color: #E1660D;
        }
      }

      .city-desc {
        padding-left: 24px;
        padding-right: 22px;
        font-size: 12px;
        color: #8B8B8B;
        padding-bottom: 12px;
        border-bottom: 1px solid #eeeeee;
      }

      .bottom-content-info {
        display: flex;
        justify-content: space-between;
        padding: 12px 22px 16px 24px;

        div img {
          height: 14px;
          vertical-align: middle;
          margin-right: 6px;
        }

        div i {
          color: $text-color-grey;
          margin-right: 6px;
          vertical-align: middle;
        }

        div span {
          font-size: 12px;
          color: #8B8B8B;
        }
      }
    }
  }
}
</style>
