<template>
  <div class="attach-files-list">
    <div class="attach-file-item flex-sb"
         v-for="(file) in fileJson">
      <p>{{ file.name }}</p>
      <el-link
        @click="download(baseAssetsUrl + file.message,file.name)"
        type="primary">下载
      </el-link>
    </div>
  </div>
</template>

<script>
import { baseAssetsUrl, baseUrl } from '@/api/config'

export default {
  name: 'AttachFilesList',
  props: ['fileList'],
  computed: {
    fileJson () {
      let filesJson = this.fileList && JSON.parse(this.fileList) || {}
      return filesJson
    },
  },
  data () {
    return {
      baseUrl: baseUrl,
      baseAssetsUrl: baseAssetsUrl
    }
  },
  methods: {
    download (url, fileName = '未知文件') {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.setAttribute('target', '_blank')
      /*
       * download的属性是HTML5新增的属性
       * href属性的地址必须是非跨域的地址，如果引用的是第三方的网站或者说是前后端分离的项目(调用后台的接口)，这时download就会不起作用。
       * 此时，如果是下载浏览器无法解析的文件，例如.exe,.xlsx..那么浏览器会自动下载，但是如果使用浏览器可以解析的文件，比如.txt,.png,.pdf....浏览器就会采取预览模式
       * 所以，对于.txt,.png,.pdf等的预览功能我们就可以直接不设置download属性(前提是后端响应头的Content-Type: application/octet-stream，如果为application/pdf浏览器则会判断文件为 pdf ，自动执行预览的策略)
       */
      fileName && a.setAttribute('download', fileName)
      a.href = url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
}
</script>

<style lang="scss"
       scoped>
.attach-files-list {
  margin-bottom: 30px;

  .attach-file-item {
    background: #FFFFFF;
    border: 1px solid #eee;
    border-radius: 2px;
    padding: 16px;
    margin-bottom: 14px;

    &:hover {
      cursor: pointer;
      color: #E1580D;
      border-color: #E1580D;
    }

    p {
      margin: 0;
    }
  }
}
</style>
