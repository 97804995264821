<template>
  <el-dialog title="意见反馈"
             :close-on-click-modal="false"
             width="40%"
             :visible.sync="dialogFormVisible">
    <el-form :model="form"
             class="add-member-form"
             ref="addMemberForm"
             label-width="120px"
             label-position="top">
      <el-form-item label="你遇到的问题或建议反馈给我们，我们会更好的为你服务。"
                    required>
        <el-input v-model="form.content" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="请留下您的手机号码，谢谢！">
        <el-input v-model="form.contactTell"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary"
                 @click="confirm"
      >确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { leaveFeedBack } from '@/api/apiFunc'

export default {
  name: 'FeedBackDialog',
  data () {
    return {
      dialogFormVisible: false,
      module: '',
      form: {
        content: '',
        contactTell: ''
      },
    }
  },
  methods: {
    init (options) {
      this.dialogFormVisible = true
    },
    confirm () {
      let data = {}
      leaveFeedBack(Object.assign({}, data, this.form)).then(res => {
        console.log(res)
        if (res.data.code === 200){
          this.$notify({
            title: '成功',
            message: '留言成功。',
            type: 'success'
          })
          this.dialogFormVisible = false;
        }
      }).catch(err => {
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>

</style>
