<template>
  <div class="w1240 flex-column">
    <div class="header df">
      <img :src="baseUrl + detail.imgs.split(',')[0]"
           style="width: 412px;height: 280px;"
           alt="">
      <div class="profile-container flex-column space-between">
        <h3 class="title">{{ detail.title }}</h3>
        <p class="progress">合作方式：{{ detail.cooperateType }}</p>
        <div>
          <span>预算价格：</span>
          <span class="meet">{{ detail.budgetAmount }}</span>
        </div>
        <div class="profile-contact flex-sb">
          <el-button style="width: 255px;height: 58px;font-size: 20px"
                     @click="contactTech"
                     v-is-login
                     type="primary">联系需求方
          </el-button>
          <span class="read-num"><i class="el-icon-view"></i> {{ detail.seeNum || 0 }}人浏览</span>
        </div>
      </div>
    </div>
    <div class="bottom flex-c-c">
      <div class="item flex-column align-center">
        <span class="content">{{ dictionary.tradeTypes[detail.tradeType] }}</span>
        <span class="title">行业</span>
      </div>
      <div class="item flex-column align-center">
        <span class="content">{{ detail.city }}</span>
        <span class="title">所属地区</span>
      </div>
      <div class="item flex-column align-center">
        <span class="content">{{ dictionary.belongTypes[detail.belongType] }}</span>
        <span class="title">所属类别</span>
      </div>
      <div class="item flex-column align-center">
        <span class="content">{{ detail.createTime }}</span>
        <span class="title">发布时间</span>
      </div>
    </div>
    <comment-dialog ref="commentsDialog"></comment-dialog>
  </div>
</template>

<script>
import { dictionary } from '@/dictionary/dictionary'
import { baseUrl } from '@/api/config'
import CommentDialog from '@/components/CommentDialog'

export default {
  name: 'SummaryDetailProfile',
  props: ['detail'],
  components: { CommentDialog },
  data () {
    return {
      dictionary: dictionary,
      baseUrl: baseUrl
    }
  },
  methods: {
    contactTech() {
      this.$refs.commentsDialog.init({title: '联系需求方',module: 'requirement',id: this.detail.id});
    },
  }
}
</script>

<style lang="scss"
       scoped>
@import "@/styles/_variables.scss";

.profile-container {
  margin-left: 20px;
  max-width: 736px;

  .title {
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .progress {
    margin-top: 0;
    font-size: 18px;
  }

  .profile {
    margin: 0 0 20px 0;
    font-size: 20px;
    line-height: 2;
  }

  .meet {
    font-size: 40px;
    color: #F50F0F;
    margin-left: 24px;
    margin-right: 22px;
  }

  .profile-contact {
    margin-top: 84px;
  }
}

.bottom {
  padding-top: 40px;
  padding-bottom: 40px;

  .item {
    width: 25%;
    padding: 0 30px;
    font-size: 18px;

    &:nth-of-type(-n+3) {
      border-right: 1px solid #eee;
    }

    .title {
      padding-top: 16px;
      color: #737373;
    }
  }
}

.read-num {
  color: $text-color-grey;
}
</style>
