<template>
  <div class="the-index">
    <div class="w1240 df flex-sb week-container">
      <div>
        <week-section :section-list="weekResult"
                      routeName="SummaryShowDetail"
                      title="本周成果关注排名"
                      class="mb132"></week-section>
        <week-section :section-list="weekProvide"
                      routeName="RequirementShowDetail"
                      title="本周供需对接排名"></week-section>
      </div>
      <div>
        <week-section title="本周成果数据采集"
                      width="400px">
          <template #weekData>
            <div class="data-get">
              <img src="../assets/images/earth.png"
                   class="earth-img"
                   alt="">
              <div class="data-get-desc">
                <span>本周采集数据</span>
                <span>{{ summaryTotal }}</span>
              </div>
            </div>
          </template>
        </week-section>
      </div>
    </div>
    <sub-section background="#3A3C48"
                 color="#ffffff"
                 title="成果大厅"
                 routeName="SummaryShow"
                 shadowText="ACHIEVEMENT"
                 shadowColor="#FFFFFF"
                 opacity="0.03">
      <template #content>
        <div class="result-room">
          <div class="result-room-item img-mb"
               @click="$router.push({name: 'SummaryShow'})">
            <img
              src="../assets/images/summary1.png"
              alt="">
            <div>电子信息</div>
          </div>
          <div class="result-room-item img-mb" @click="$router.push({name: 'SummaryShow'})">
            <img src="../assets/images/summary2.png"
                 alt="">
            <div>生物医药</div>
          </div>
          <div class="result-room-item img-mb" @click="$router.push({name: 'SummaryShow'})">
            <img src="../assets/images/summary3.png"
                 alt="">
            <div>智能制造</div>
          </div>
          <div class="result-room-item img-mb" @click="$router.push({name: 'SummaryShow'})">
            <img src="../assets/images/summary4.png"
                 alt="">
            <div>能源化工</div>
          </div>
        </div>
      </template>
    </sub-section>
    <sub-section background="#ffffff"
                 color="#000000"
                 title="需求大厅"
                 routeName="CooperationRequirement"
                 shadowText="DEMAND"
                 shadowColor="#CDD3DE"
                 opacity="0.3">
      <template #content>
        <div class="result-room mb40">
          <div v-for="requireRow1 in requirementHall.slice(0,4)" :key="requireRow1.id" class="result-room-item img-mb" @click="$router.push({name: 'CooperationRequirement'})">
            <img :src="baseUrl + requireRow1.imgs"
                 alt="">
            <div>{{ requireRow1.title }}</div>
          </div>
<!--          <div class="result-room-item img-mb" @click="$router.push({name: 'CooperationRequirement'})">-->
<!--            <img src="../assets/images/requirement2.png"-->
<!--                 alt="">-->
<!--            <div>发动机高合金零部件精密铸造</div>-->
<!--          </div>-->
<!--          <div class="result-room-item img-mb" @click="$router.push({name: 'CooperationRequirement'})">-->
<!--            <img src="../assets/images/requirement3.png"-->
<!--                 alt="">-->
<!--            <div>饲料生产的关键技术应用</div>-->
<!--          </div>-->
<!--          <div class="result-room-item img-mb" @click="$router.push({name: 'CooperationRequirement'})">-->
<!--            <img src="../assets/images/requirement4.png"-->
<!--                 alt="">-->
<!--            <div>无花果果干烘干技术</div>-->
<!--          </div>-->
        </div>
        <div class="result-room">
          <div class="result-room-item img-mb"
               v-for="requireRow2 in requirementHall.slice(4,8)"
               :key="requireRow2.id"
               @click="$router.push({name: 'CooperationRequirement'})">
            <img :src="baseUrl + requireRow2.imgs"
                 alt="">
            <div>{{requireRow2.title}}</div>
          </div>
<!--          <div class="result-room-item img-mb" @click="$router.push({name: 'CooperationRequirement'})">-->
<!--            <img src="../assets/images/requirement6.png"-->
<!--                 alt="">-->
<!--            <div>特种氧化铝陶瓷基板技术</div>-->
<!--          </div>-->
<!--          <div class="result-room-item img-mb" @click="$router.push({name: 'CooperationRequirement'})">-->
<!--            <img src="../assets/images/requirement7.png"-->
<!--                 alt="">-->
<!--            <div>阀体感应装置加工工艺技术</div>-->
<!--          </div>-->
<!--          <div class="result-room-item img-mb" @click="$router.push({name: 'CooperationRequirement'})">-->
<!--            <img src="../assets/images/requirement8.png"-->
<!--                 alt="">-->
<!--            <div>突破电感器耐电流载荷技术</div>-->
<!--          </div>-->
        </div>
      </template>
    </sub-section>
    <sub-section style="margin-top: 0"
                 background="#F4F5F7"
                 color="#000000"
                 title="最新动态"
                 routeName="ActiveNews"
                 shadowText="RECENT NEWS"
                 shadowColor="#CDD3DE"
                 opacity="0.3">
      <template #content>
        <div class="new-dynamic">
          <img :src="newsList[0]?.headUrl ? (baseUrl + newsList[0]?.headUrl) : defaultNewast">
          <div class="news">
            <div class="news-head">
              <span @click="dynamic='news'"
                    :class="[dynamic === 'news' ? 'active' : '']">新闻动态</span>
              <span @click="dynamic='active'"
                    :class="[dynamic === 'active' ? 'active' : '']">最新活动</span>
            </div>
            <template v-if="dynamic === 'news'">
              <div class="news-item"
                   v-for="(newItem) in newsList"
                   :key="newItem.id"
                   @click="$router.push({name: 'NewsDetail',query: {
                     id: newItem.id
                   }})">
                <span class="text-ellipsis">{{ newItem.title }}</span>
                <span>{{ newItem.createTime.split(' ')[0] }}</span>
              </div>
            </template>
            <template v-if="dynamic === 'active'">
              <div class="news-item"
                   v-for="(activityItem) in activityList"
                   :key="activityItem.id"
                   @click="$router.push({name: 'ResultActive',query: {
                     id: activityItem.id
                   }})">
                <span class="text-ellipsis">{{ activityItem.title }}</span>
                <span>{{ activityItem.createTime.split(' ')[0] }}</span>
              </div>
            </template>
          </div>
        </div>
      </template>
    </sub-section>
    <sub-section background="#3A3C48"
                 color="#ffffff"
                 title="专家库"
                 routeName="ProfessionalPool"
                 shadowText="EXPEXTS"
                 shadowColor="#FFFFFF"
                 opacity="0.03">
      <template #content>
        <div class="result-room avatar-container">
          <div class="result-room-item img-mb1"
               v-for="item in experts"
               :key="item.id"
               @click="goExpertDetail(item.id)">
            <img :src="(item.headUrl ? (baseUrl + item.headUrl) : defaultAvatar)"
                 alt="">
            <div class="profession">{{ item.name }}</div>
            <div class="profession-line"></div>
          </div>
        </div>
      </template>
    </sub-section>
    <sub-section background="#F4F5F7"
                 color="#000000"
                 title="服务商"
                 routeName="ServiceConstructor"
                 shadowText="RECENT NEWS"
                 shadowColor="#CDD3DE"
                 opacity="0.3">
      <template #content>
        <div class="service-store mb16">
          <img
            v-for="constructor in constructors"
            :key="constructor.id"
            @click="goLink(constructor.url)"
            :src="baseUrl + constructor.logo"
            class="service-store-item">
        </div>
      </template>
    </sub-section>
    <sub-section background="#ffffff"
                 color="#000000"
                 title="投融资服务"
                 shadowText="INVESTMENT SERVICE"
                 shadowColor="#CDD3DE"
                 :hideBtn="true"
                 style="padding-bottom: 40px;"
                 opacity="0.3">
      <template #content>
        <div class="result-room">
          <div class="result-room-item"
               v-for="(item) in investment"
               :key="item.id"
               @click="goLink(item.url)"
               style="width: 293px;height: 280px;box-shadow: none">
            <img :src="baseUrl + item.logo"
                 style="width: 293px;height: 280px;"
                 alt="">
            <el-button type="primary"
                       style="width: 200px;height: 38px;border-radius: 18px;position:absolute;bottom: 34px">立即申请>>
            </el-button>
          </div>
        </div>
      </template>
    </sub-section>
    <!--    <div class="footer-container bottom-images">
          <img src="../assets/images/i1.png"
               @click="goLink('http://zr.scppc.org.cn/list-50-1.html')"
               alt="">
          <img src="../assets/images/i2.png"
               @click="goLink('http://tech.scppc.org.cn/')"
               alt="">
          <img src="../assets/images/i3.png"
               @click="goLink('http://ztsp.scppc.org.cn/')"
               alt="">
          <img src="../assets/images/i4.png"
               @click="goLink('http://fuhua.scppc.org.cn/index.php')"
               alt="">
        </div>-->
  </div>
</template>

<script>
import WeekSection from '@/components/WeekSection'
import SubSection from '@/components/SubSection'
import {
  getActiveList, getConstructorList,
  getExpertsComment, getInvestment,
  getNewsList,
  getProfessionalList, getRequirementList,
  getRequirementRank,
  getSummaryRank,
} from '@/api/apiFunc'
import { baseUrl } from '@/api/config'

export default {
  name: 'index',
  components: { SubSection, WeekSection },
  data () {
    return {
      defaultNewast: require('@/assets/images/newast_dynamic.png'),
      baseUrl: baseUrl,
      defaultAvatar: require('@/assets/images/default_experts_avatar.png'),
      weekResult: [],
      weekProvide: [],
      newsList: [],
      activityList: [],
      experts: [],
      constructors: [],
      investment: [],
      dynamic: 'news',
      summaryTotal: 0,
      requirementHall: [],
    }
  },
  mounted () {
    this.initPage()
  },
  methods: {
    initPage () {
      // 成果关注排名
      getSummaryRank().then(res => {
        this.weekResult = res?.data?.result?.records || []
        this.summaryTotal = res?.data?.result?.total
      }).catch(err => {
        console.log(err)
      })
      // 供需对接排名
      getRequirementRank().then(res => {
        this.weekProvide = res?.data?.result?.records || []
      }).catch(err => {
        console.log(err)
      })
      // 需求大厅
      getRequirementList({ pageSize: 8 }).then(res => {
        this.requirementHall = res?.data?.result?.records || []
      }).catch(err => {
        console.log(err)
      })
      // 新闻
      getNewsList({ pageSize: 5 }).then(res => {
        this.newsList = res?.data?.result?.records || []
      })
      // 活动
      getActiveList({ pageSize: 5 }).then(res => {
        this.activityList = res?.data?.result?.records || []
      })
      // 专家库
      getProfessionalList({ pageSize: 4 }).then(res => {
        this.experts = res?.data?.result?.records || []
      })
      // 服务商
      getConstructorList({ pageSize: 10 }).then(res => {
        this.constructors = res?.data?.result?.records || []
      })
      // 投融资服务
      getInvestment().then(res => {
        this.investment = res?.data?.result?.records || []
      })
    },
    goLink (url) {
      window.open(url, '_blank')
    },
    goExpertDetail(id){
      this.$router.push({name: 'ProfessionalDetail',query: {id: id}})
    }
  }
}
</script>

<style lang="scss">
.the-index {
  .mb16 {
    margin-bottom: 16px;
  }

  .mb90 {
    margin-bottom: 94px;
  }

  .mb132 {
    margin-bottom: 132px;
  }

  .mb40 {
    margin-bottom: 40px;
  }

  .mb80 {
    margin-bottom: 80px;
  }

  .week-container {
    padding: 40px 0;
    align-items: flex-start;

    .data-get {
      display: flex;
      flex-direction: column;
      align-items: center;

      .earth-img {
        width: 398px;
        height: 376px;
      }

      .data-get-desc {
        margin-top: 20px;

        span:first-child {
          font-size: 20px;
          font-weight: 400;
          color: #000000;
          margin-right: 20px;
        }

        span:last-child {
          font-size: 24px;
          font-weight: bold;
          color: #E68140;
        }
      }
    }
  }

  .result-room {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .result-room-item {
      width: 286px;
      height: 286px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0px 2px 2px 0px rgba(8, 1, 3, 0.13);
      position: relative;

      &:hover {
        cursor: pointer;
        /* &:before{
           text-align: center;
           line-height: 286px;
           color: #ffffff;
           font-size: 14px;
           font-weight: 700;
           content: "行业分类 | 合作方式 | 联系企业";
           display: block;
           position: absolute;
           top: 0;
           left: 0;
           right: 0;
           bottom: 0;
           background: #E68140;
           opacity: 0.7;
         }*/
      }

      img {
        width: 286px;
        height: 241px;
      }

      div {
        background: #ffffff;
        font-size: 14px;
        color: #232326;
        width: 100%;
        text-align: center;
        padding: 12px 0;

        &:hover {
          cursor: pointer;
          color: #E68140;
        }
      }
    }

    .img-mb {
      position: relative;

      &:hover.img-mb::before {
        font-size: 14px;
        width: 286px;
        height: 286px;
        text-align: center;
        line-height: 286px;
        content: "查看更多>>";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        color: #fff;
        background-color: #e68140;
        opacity: 0.8;
      }
    }
  }

  .new-dynamic {
    display: flex;

    img {
      width: 664px;
      height: 420px;
    }

    .news {
      width: 530px;
      height: 420px;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      padding-left: 20px;

      .news-head {
        font-size: 20px;
        font-weight: bold;
        color: #1F1F1F;
        padding: 20px 10px 10px 10px;
        border-bottom: 1px solid #eeeeee;

        span:hover {
          cursor: pointer;
        }

        span:first-child {
          margin-right: 20px;
        }
      }

      .news-item {
        display: flex;
        justify-content: space-between;
        padding: 37px 10px 10px 10px;
        border-bottom: 1px solid #eeeeee;

        &:hover {
          cursor: pointer;
          color: #E68140;
        }

        span:first-child {
          width: 385px;
          font-size: 18px;
          color: #1F1F1F;

          &:hover {
            cursor: pointer;
            color: #E68140;
          }
        }

        span:last-child {
          font-size: 18px;
          color: #838383;

          &:hover {
            cursor: pointer;
            color: #E68140;
          }
        }
      }
    }
  }

  .avatar-container {
    .img-mb1 {
      position: relative;

      &:hover.img-mb1::before {
        font-size: 14px;
        content: "查看更多>>";
        width: 286px;
        height: 286px;
        text-align: center;
        line-height: 286px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        color: #fff;
        background-color: #e68140;
        opacity: 0.8;
      }
    }

    .result-room-item {
      font-size: 0;

      &:hover {
        cursor: pointer;
      }

      background: #ffffff;

      img {
        width: 146px;
        height: 146px;
        margin-top: 20px;
      }

      .profession {
        font-size: 20px;
        color: #000000;
        padding: 0;
        padding-top: 28px;
      }

      .profession-line {
        width: 26px;
        height: 5px;
        background: #E68140;
        padding: 0;
        margin-bottom: 15px;
        margin-top: 6px;
      }

      .profession-role {
        font-size: 16px;
        color: #000000;
        padding: 0;
      }
    }
  }

  .service-store {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .service-store-item {
      width: 228px;
      height: 100px;
      margin-bottom: 16px;

      &:hover {
        cursor: pointer;
        color: #E68140;
      }
    }
  }

  .footer-container {
    width: 1240px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .bottom-images {
    img {
      width: 286px;
      height: 60px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
