<template>
  <div class="my-published-summary">
    <div class="table-operate flex-sb">
      <el-button type="primary"  @click="$router.push({name: 'PublishResult'})">发布成果
      </el-button>
      <el-input
        clearable
        style="width: 220px;"
        placeholder="请输入成果名称"
        suffix-icon="el-icon-search"
        v-model="searchOptions.title"
      >
      </el-input>
    </div>
    <div class="table-content">
      <el-table
        :data="listData"
        style="width: 100%">
        <el-table-column
          width="160"
          show-overflow-tooltip
          label="标题"
          prop="title">
        </el-table-column>
        <el-table-column
          width="160"
          show-overflow-tooltip
          label="发布时间"
          prop="createTime">
        </el-table-column>
        <el-table-column
          width="200"
          label="技术领域"
          show-overflow-tooltip
          prop="domain">
        </el-table-column>
        <el-table-column
          label="成果状态"
          width="100"
          prop="achievementStatus">
          <template slot-scope="scope">
            {{ dictionary.achievementStatus[scope.row.achievementStatus] }}
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="350">
          <template slot-scope="scope">
            <el-button size="mini"
                       @click="$router.push({name: 'SummaryShowDetail',query:{id: scope.row.id}})">查看
            </el-button>
            <el-button size="mini"
                       @click="inviteProfessional(scope.row.id)">邀请专家
            </el-button>
<!--                        <el-button size="mini" @click="$router.push({name: 'PublishResult',query:{row: scope.row}})">编辑</el-button>-->
            <el-popconfirm
              title="确定删除吗？"
              @confirm="confirmDelSummary(scope.row.id)"
            >
              <el-button
                slot="reference"
                type="danger"
                style="margin-left: 4px;"
                size="mini">删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        :page-size="pageSize"
        :current-page.sync="pageNo"
        layout="prev, pager, next"
        :total="total"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <el-dialog title="邀请专家"
               :close-on-click-modal="false"
               width="70%"
               :visible.sync="ProfessionalDialog">
      <el-table :data="professionList"
                @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="50">
        </el-table-column>
        type>
        <el-table-column property="name"
                         label="姓名"
                         width="200">
        </el-table-column>
        <el-table-column property="post"
                         label="职位">
        </el-table-column>
        <el-table-column property="positionalTitles"
                         label="职称">
        </el-table-column>
        <el-table-column property="domain"
                         label="研究领域">
        </el-table-column>
      </el-table>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="ProfessionalDialog = false">取 消</el-button>
        <el-button type="primary"
                   @click="confirmSelect"
        >确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deleteSummary, getDictByDicCode, getProfessionalList, getSummaryList, inviteExperts } from '@/api/apiFunc'
import { dictionary } from '@/dictionary/dictionary'

export default {
  name: 'MyPublishedSummary',
  data () {
    return {
      ProfessionalDialog: false,
      dictionary: dictionary,
      searchOptions: {
        title: ''
      },
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listData: [],
      professionList: [],
      selectData: [],
      userId: this.$store.state.userInfo.userInfo.id
    }
  },
  watch: {
    searchOptions: {
      // immediate: true,
      deep: true,
      handler () {
        this.searchSummary()
      }
    }
  },
  mounted () {
    getDictByDicCode('need_pub_trade_type').then(res => {
      res.data.unshift({ label: '不限', value: '' })
      this.tradeTypes = res.data
    })

    this.citys = dictionary.citys
    this.getPageList()
  },
  methods: {
    confirmDelSummary (id) {
      deleteSummary(id).then(res => {
        this.$myNotice.success({ msg: '删除成功' })
        this.getPageList()
      }).catch(err => {
        console.log(err)
      })
    },
    getPageList () {
      let options = Object.assign(
        {},
        this.searchOptions,
        { pageNo: this.pageNo, pageSize: this.pageSize, userId: this.userId })
      console.log('查询条件：', options)
      getSummaryList(options).then(res => {
        this.listData = res?.data?.result?.records || []
      }).catch(err => {
        console.log(err)
      })
    },
    searchSummary () {
      this.pageNo = 1
      this.getPageList()
    },
    currentChange () {
      this.getPageList()
    },
    inviteProfessional (achiveId) {
      this.ProfessionalDialog = true
      this.achiveId = achiveId
      getProfessionalList({ pageSize: 999, pageNo: 1 }).then(res => {
        this.professionList = res?.data?.result?.records || []
      })
    },
    handleSelectionChange (val) {
      this.selectData = val
    },
    confirmSelect () {
      this.ProfessionalDialog = false
      let expertsIdArr = this.selectData.map(item => {
        return item.id
      })
      console.log(expertsIdArr.join(','))
      inviteExperts({
        expertIds: expertsIdArr.join(','),
        achievementId: this.achiveId,
      }).then(res => {
        if (res.data.code === 200) {
          this.$notify({
            title: '成功',
            message: '邀请专家成功.',
            type: 'success'
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
  }
}
</script>

<style lang="scss">
.my-published-summary {
  min-height: calc(100vh - 338px);

  .table-operate {
    padding-bottom: 10px;
  }

  .table-content {
    min-height: 600px;
  }

  .pagination {
    text-align: center;
    padding: 20px 0;
  }
}
</style>
