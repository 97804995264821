<template>
  <div class="publish-requirement">
    <div class="header-container">
      <div class="bread-list">
        <div class="top-search">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{name:'TheIndex'}">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{name:'CooperationRequirement'}">合作需求</el-breadcrumb-item>
            <el-breadcrumb-item>发布需求</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <el-divider class="top-divider"></el-divider>
      <div class="w1240">
        <div class="top-form">
          <div class="top-form-container">
            <el-form ref="form"
                     :rules="rules"
                     :model="formData"
                     label-width="130px">
              <el-row>
                <el-col>
                  <el-form-item label="需求名称："
                                prop="title"
                                required>
                    <el-input v-model="formData.title"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="信息有效时间："
                                prop="endTime">
                    <el-date-picker type="date"
                                    placeholder="选择日期"
                                    v-model="formData.endTime"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="需求状态："
                                prop="needsStatus">
                    <el-select v-model="formData.needsStatus"
                               placeholder="请选择">
                      <el-option
                        v-for="item in needStatus"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="所属地区："
                                prop="city">
                    <el-select v-model="formData.city"
                               placeholder="请选择">
                      <el-option
                        v-for="item in citys"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="行业分类："
                                prop="tradeType">
                    <el-select v-model="formData.tradeType"
                               placeholder="请选择">
                      <el-option
                        v-for="item in tradeTypes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="所属类别："
                                prop="belongType">
                    <el-select v-model="formData.belongType"
                               placeholder="请选择">
                      <el-option
                        v-for="item in belongTypes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="合作方式："
                                prop="cooperateType">
                    <el-input v-model="formData.cooperateType"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="联系人："
                                prop="contactName">
                    <el-input v-model="formData.contactName"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="联系方式："
                                prop="contactTell"
                                required>
                    <el-input v-model="formData.contactTell"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <el-form-item label="预算价格："
                                prop="budgetAmount">
                    <el-input v-model="formData.budgetAmount"></el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="应用领域："
                                prop="appRange">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4}"
                      placeholder="请输入内容"
                      v-model="formData.appRange">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="需求详情："
                                prop="needContent"
                                required>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4}"
                      placeholder="请输入内容"
                      v-model="formData.needContent">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <el-form-item label="展示图片：">
                    <el-upload
                      :limit="3"
                      :on-success="onSuccessImgs"
                      :on-remove="onRemoveImgs"
                      list-type="picture-card"
                      :file-list="imgsList"
                      :action="baseUrl + '/sys/common/upload'"
                      :headers="headers"
                      multiple>
                      <i class="el-icon-plus"></i>
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="附件：">
                    <el-upload
                      :limit="10"
                      :on-success="onSuccessAttach"
                      :on-remove="onRemoveAttach"
                      :file-list="attachList"
                      :action="baseUrl + '/sys/common/upload'"
                      :headers="headers"
                      multiple>
                      <el-button size="small"
                                 type="primary">点击上传
                      </el-button>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div class="btn-container flex-c-c">
              <el-button type="primary"
                         @click="publishRequirement">提交发布申请
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addRequirement, editRequirement, getDictByDicCode } from '@/api/apiFunc'
import { dictionary } from '@/dictionary/dictionary'
import store from '@/store'
import { baseUrl } from '@/api/config'
import { transformAttachFileList, transformImgsList } from '@/utils/unity_funcs'

export default {
  name: 'PublishRequirement',
  data () {
    return {
      headers: {
        'Authorization': store.state.userInfo.token || localStorage.getItem('token'),
        'X-Access-Token': store.state.userInfo.token || localStorage.getItem('token')
      },
      citys: [],
      baseUrl: baseUrl,
      formData: {
        title: '',
        endTime: '',
        city: '',
        needsStatus: '',
        tradeType: '',
        belongType: '',
        cooperateType: '',
        contactName: '',
        contactTell: '',
        budgetAmount: '',
        appRange: '',
        needContent: '',
        imgs: '',
        file: [],
      },
      belongTypes: [],
      needStatus: [],
      tradeTypes: [],
      imgsList: [],
      attachList: [],
      rules: {
        title: [{ required: true, message: '请输入需求名称', trigger: 'blur' }],
        endTime: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        needsStatus: [{ required: true, message: '请选择需求状态', trigger: 'change' }],
        city: [{ required: true, message: '请选择所属地区', trigger: 'change' }],
        tradeType: [{ required: true, message: '请选择行业分类', trigger: 'change' }],
        belongType: [{ required: true, message: '请选择所属类别', trigger: 'change' }],
        contactName: [{ required: true, message: '请输入联系人', trigger: 'change' }],
        contactTell: [{ required: true, message: '请输入联系方式', trigger: 'change' }],
        needContent: [{ required: true, message: '请输入需求详情', trigger: 'change' }],
      }
    }
  },
  async mounted () {
    await getDictByDicCode('need_pub_belong_type').then(res => {
      this.belongTypes = res.data
    })
    await getDictByDicCode('need_pub_needs_status').then(res => {
      this.needStatus = res.data
    })
    await getDictByDicCode('need_pub_trade_type').then(res => {
      this.tradeTypes = res.data
    })
    this.citys = dictionary.citys.slice(1)
    this.$nextTick(() => {
      if (this.$route.query.row) {
        this.$route.query.row.belongType = String(this.$route.query.row.belongType)
        this.$route.query.row.tradeType = String(this.$route.query.row.tradeType)
        this.$route.query.row.needsStatus = String(this.$route.query.row.needsStatus)
        this.formData = this.$route.query.row
        this.attachList = transformAttachFileList(this.formData.file)
        this.imgsList = transformImgsList(this.formData.imgs)
      }
    })
  },
  methods: {
    publishRequirement () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 处理图片路径存储格式
          this.handleArrToString()
          if (Array.isArray(this.formData.file) && this.formData.file.length === 0){
            this.formData.file = JSON.stringify(this.formData.file)
          }
          let reqMethod = this.formData.id ? editRequirement : addRequirement
          this.formData.userId = this.$store.state.userInfo.userInfo.id
          reqMethod(this.formData).then(res => {
            if (res.data.code === 200) {
              this.$notify({
                title: '成功',
                message: '操作成功.',
                type: 'success'
              })
              this.$router.push({ name: 'CooperationRequirement' })
            }
          }).catch(error => {
            console.log(error)
          })
        }
      })
    },
    handleArrToString () {
      let imgsArr = this.imgsList.map(item => {
        return item.message ? item.message : item.response && item.response.message
      })
      this.formData.imgs = imgsArr.join(',')
    },
    onSuccessImgs (response, file, fileList) {
      this.imgsList = fileList
    },
    onRemoveImgs (file, fileList) {
      this.imgsList = fileList
    },
    onSuccessAttach (response, file, fileList) {
      this.formData.file = fileList.map(item => {
        return {
          name: item.name,
          message: item.message ? item.message : item.response && item.response.message
        }
      })
      this.formData.file = JSON.stringify(this.formData.file)
      console.log(this.formData.file)
    },
    onRemoveAttach (file, fileList) {
      this.formData.file = fileList.map(item => {
        return {
          name: item.name,
          message: item.message && item.message || item.response && item.response.message
        }
      })
      this.formData.file = JSON.stringify(this.formData.file)
    },
  }
}
</script>
<style lang="scss">
.publish-requirement {
  .el-select {
    width: 100%;
  }

  .btn-container {
    margin-bottom: 40px;
  }
}

.step-class {
  margin-bottom: 40px;
}
</style>
