<template>
  <div class="summary-idea">
    <content-header title="已邀请专家">
      <template slot="content">
        <div class="avatar-list">
          <el-avatar
            v-for="(item) in expertsList"
            :key="item.id"
            :size="80"
            :src="baseUrl + item.experts.headUrl"></el-avatar>
        </div>
      </template>
    </content-header>
    <content-header title="专家评价">
      <template slot="content">
        <div class="mark-list">
          <div class="mark-list-item flex-sb"
               v-for="(i) in expertsList"
               :key="i.id">
            <div class="flex-column">
              <span class="name">{{i.experts.name}}</span>
              <div class="profile">
                <span>{{i.experts.company}}</span>
                <span>{{i.experts.domain}}</span>
                <span>{{i.experts.positionalTitles}}</span>
              </div>
            </div>
            <div class="mark-content">
              {{i.comment ? i.comment : '暂未评价'}}
            </div>
          </div>
        </div>
      </template>
    </content-header>
  </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'
import { getExpertsComment } from '@/api/apiFunc'
import { baseUrl } from '@/api/config'

export default {
  name: 'ProfessionComment',
  components: { ContentHeader },
  props: ['summaryInfo'],
  mounted () {
    getExpertsComment(this.summaryInfo.id).then(res => {
      this.expertsList = res?.data?.result?.records || []
    }).catch(err => {
      console.log(err)
    })
  },
  data () {
    return {
      baseUrl: baseUrl,
      expertsList: []
    }
  }
}
</script>

<style lang="scss"
       scoped>
.avatar-list {
  .el-avatar {
    margin-right: 40px;
  }
}

.mark-list {
  .mark-list-item {
    padding: 10px 30px;
    background: #fff;
    margin-bottom: 18px;
    border: 1px solid #E8E5E5;

    &:hover {
      cursor: pointer;
      border-color: #E1580D;
    }

    .name {
      padding-left: 8px;
    }

    .profile {
      color: #8b8b8b;

      span {
        font-size: 14px;
        padding: 0 8px;

        &:nth-of-type(-n+2) {
          border-right: 1px solid #8b8b8b;
        }
      }
    }
  }

  .mark-content {
    width: 900px;
    font-size: 14px;
    border-left: 1px solid #eee;
    padding-left: 30px;
  }
}
</style>
