<template>
  <div class="contact-me">
<!--    <div class="table-operate flex-sb">-->
<!--      <el-input-->
<!--        clearable-->
<!--        style="width: 220px;"-->
<!--        placeholder="请输入需求名称"-->
<!--        suffix-icon="el-icon-search"-->
<!--        v-model="searchOptions.title"-->
<!--      >-->
<!--      </el-input>-->
<!--    </div>-->
    <div class="table-content" style="width: 100%;">
      <el-table
        :data="listData"
        style="width: 100%">
        <el-table-column
          width="160"
          show-overflow-tooltip
          label="联系人电话"
          prop="userPhone">
        </el-table-column>
        <el-table-column
          width="400"
          show-overflow-tooltip
          label="系统内容"
          prop="sysContent">
        </el-table-column>
        <el-table-column
          width="300"
          label="用户留言"
          show-overflow-tooltip
          prop="userContent">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        :page-size="pageSize"
        :current-page.sync="pageNo"
        layout="prev, pager, next"
        :total="total"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>

import { contactMeList } from '@/api/apiFunc'

export default {
  name: 'ContactMe',
  data () {
    return {
      // searchOptions: {
      //   title: ''
      // },
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listData: [],
      userId: this.$store.state.userInfo.userInfo.id
    }
  },
  watch: {
    // searchOptions: {
    //   deep: true,
    //   handler () {
    //     this.search()
    //   }
    // }
  },
  mounted () {
    this.getPageList()
  },
  methods: {
    getPageList () {
      let options = Object.assign(
        {},
        this.searchOptions,
        { pageNo: this.pageNo, pageSize: this.pageSize, toUserId: this.userId })
      console.log('查询条件：', options)
      contactMeList(options).then(res => {
        this.listData = res?.data?.result?.records || []
      }).catch(err => {
        console.log(err)
      })
    },
    // search () {
    //   this.pageNo = 1
    //   this.getPageList()
    // },
    currentChange () {
      this.getPageList()
    },
  }
}
</script>

<style lang="scss">
.contact-me {
  min-height: calc(100vh - 338px);

  .table-operate {
    padding-bottom: 10px;
  }

  .table-content {
    min-height: 600px;
  }

  .pagination {
    text-align: center;
    padding: 20px 0;
  }
}
</style>
