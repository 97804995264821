<template>
  <div class="service-constructor">
    <div class="header-container">
      <div class="full-container">
        <div class="bread-list">
          <div class="top-search">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{name:'TheIndex'}">首页</el-breadcrumb-item>
              <el-breadcrumb-item>服务商</el-breadcrumb-item>
            </el-breadcrumb>
            <!--            <div class="search-bar">-->
            <!--              <el-input-->
            <!--                suffix-icon="el-icon-search"-->
            <!--                v-model="value"-->
            <!--              >-->
            <!--              </el-input>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
      <div class="w1240">
        <div class="service-store"
             style="margin-top: 40px;">
          <img
            @click="newWindow(item.url)"
            v-for="(item,index) in listData"
            :key="index"
            :src="(baseUrl + item.logo)"
            class="service-store-item">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getConstructorList } from '@/api/apiFunc'
import { baseUrl } from '@/api/config'

export default {
  name: 'ServiceConstructor',
  data () {
    return {
      listData: [],
      baseUrl: baseUrl
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      getConstructorList({ pageSize: 999 }).then(res => {
        this.listData = res?.data?.result?.records || []
      }).catch(error => {
        console.log(error)
      })
    },
    newWindow (url) {
      window.open(url, '_blank')
    },
  },
}
</script>

<style lang="scss">
.service-constructor {
  min-height: calc(100vh - 338px);
  background: #fff;

  .header-container {
    .top-search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 0 24px 0;
    }

    .bread-list {
      width: 1240px;
      margin: 0 auto;
    }
  }
  .full-container {
    border-bottom: 1px solid #eeeeee;
  }

  .service-store {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    //justify-content: space-between;
    flex-wrap: wrap;

    .service-store-item {
      width: 290px;
      height: 100px;
      border: 1px solid #eee;
      margin: 12px 8px;

      &:hover {
        cursor: pointer;
        color: #E68140;
      }
    }
  }
}
</style>
